.banner {
	&.pageBanner {
		position: relative;
		height: 80svh;
		max-height: 650px;
		.item_wrap {
			background-color: transparent;
			position: static;
			min-height: unset;
			height: 100%;
			.pageBannerImg {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			.container .banner_content {
				h1,
				h2 {
					font-size: 3rem;
					strong {
						display: block;
						font-size: 150%;
					}
				}
				p {
					font-size: 1.25rem;
					font-weight: 300;
					margin-bottom: 0.5em;
				}
				.link {
					margin-top: 1.5em;
					display: inline-block;
				}
				> * {
					&:nth-child(1) {
						animation-name: fadeInForUpText;
						animation-duration: 1000ms;
					}
					&:nth-child(2) {
						animation-name: fadeInForUpText;
						animation-duration: 1400ms;
					}
					&:nth-child(3) {
						animation-name: fadeInForUpText;
						animation-duration: 1800ms;
					}
				}
			}
		}

		@media (max-width: 1560px) {
			max-height: 600px;
			.item_wrap .container .banner_content {
				h1,
				h2 {
					font-size: 2rem;
				}
				p {
					font-size: 1rem;
				}
				.link {
					font-size: 1.35rem;
				}
			}
		}

		@media (max-width: 1199px) {
			max-height: 550px;
			.item_wrap .container .banner_content {
				h1,
				h2 {
					font-size: 1.75rem;
				}
				p {
					font-size: 0.875rem;
				}
				.link {
					margin-top: 1em;
				}
			}
		}

		@media (max-width: 991px) {
			max-height: 500px;
			.item_wrap .container .banner_content {
				h1,
				h2 {
					font-size: 1.5rem;
					margin-bottom: 0.5em;
				}
			}
		}

		@media (max-width: 767px) {
			.item_wrap .container .banner_content {
				h1,
				h2 {
					font-size: 1.25rem;
				}
			}
		}
	}
}

.ant-breadcrumb {
	position: absolute;
	bottom: 0px;
	left: 1rem;
	z-index: 3;
	padding: 0;
	ol {
		background-color: #fff;
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;
		padding: 0.75em 0.5em;
		white-space: nowrap;
		line-height: normal;
		li {
			list-style: none;
			color: @primary-color;
			font-size: 1.125rem;
			text-transform: uppercase;
			font-weight: 400;
			border-right: solid 1px #000;
			padding: 0 0.5em;
			&:last-child {
				border-right: none;
				color: @primary-color;
			}
			a {
				color: #000;
				font-size: 1.125rem;
				display: block;
				text-transform: uppercase;
				font-weight: 400;
				text-decoration: none;
				transition: opacity 400ms;
				&:hover {
					color: @primary-color;
				}
			}
			.ant-breadcrumb-separator {
				display: none;
			}
		}
	}

	@media (max-width: 1560px) {
		ol li {
			font-size: 1rem;
			a {
				font-size: 1rem;
			}
		}
	}
}
