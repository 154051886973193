.infoTableWrap {
  .ant-table {
    background: transparent;
    border: 1px solid #3e3e3e;
    border-radius: 0px;

    .ant-table-thead {
      th {
        background: @secondary-color;
        color: #e5e5e5;
        font-size: 1rem;
        padding: 10px 15px;
        border-radius: 0 !important;
        border-bottom: 0;

        &:before {
          display: none;
        }
      }
    }

    .ant-table-tbody tr {
      td {
        border-bottom: 1px solid #3e3e3e;

        &.ant-table-cell-row-hover {
          background: #282828;
        }
      }

      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .noData {
    text-align: center;
    font-size: 1rem;
    color: rgb(255 255 255 / 70%);
  }

  .tooltipIcon {
    width: 16px;
    filter: invert(1);
    margin-left: 2px;
  }

  &.customTableWrap {
    table {
      width: 100%;
      background: transparent;
      border: 1px solid #3e3e3e;
      border-radius: 0px;

      thead {
        th {
          background: @secondary-color;
          color: #e5e5e5;
          font-weight: 500;
          font-size: 1rem;
          padding: 10px 15px;
          border-radius: 0 !important;
          border-bottom: 0;
        }
      }

      tbody {
        tr {
          td {
            border-bottom: 1px solid #3e3e3e;
            padding: 16px;
            // text-align: center;

            &.ant-table-cell-row-hover {
              background: #282828;
            }

            &.text-left {
              text-align: left;
            }
            .viewFileBtn {
              border-radius: 5px;
              border: 1px solid @secondary-color;
              background-color: @secondary-color;
              color: @white;
              text-decoration: none;
              font-size: 90%;
              font-weight: 400;
              padding: 5px 10px;
              margin: auto;
              white-space: nowrap;
              &:focus-visible,
              &:hover {
                background-color: transparent;
                color: @primary-color;
                border-color: @primary-color;
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }

      .darkRow {
        background-color: #1e1e1e;
      }

      .viewFileBtn {
        background-color: transparent;
        border: 0;
        font-weight: 500;
        color: @secondary-color;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
          color: @primary-color;
        }
      }
    }
  }
}

.trackShipmentPage {
  .sc_title .main_title::before {
    display: none;
  }

  .trackingInfoBlock {
    padding-bottom: 30px;
    // border-bottom: 1px solid rgb(255 255 255 / 30%);
    margin-bottom: 50px;
  }
}

.attachFormWrap {
  max-width: 520px;

  .trackInfoForm {
    border: 1px solid rgb(255 255 255 / 20%);
    padding: 40px 40px;
  }

  .attachementLink {
    padding: 10px 20px;
    background-color: #ccc;

    p {
      font-size: 18px;
      margin: 0;
      color: #000;
      display: flex;

      .link {
        margin-left: 15px;
        cursor: pointer;
        margin-left: auto;
        color: @secondary-color;
        font-weight: 500;
        border-bottom: 1px solid @secondary-color;
        transition: all 0.5s ease;

        &:hover {
          color: @primary-color;
          border-color: @primary-color;
        }
      }
    }
  }
}

.bookingReportWrap {
  .header {
    display: flex;
    flex-direction: column;
    margin: 50px 0 0;
    padding: 15px;
    border: 1px solid #999;

    p {
      margin: 0;
      padding: 5px 15px;
    }

    hr {
      border: none;
      border-bottom: 1px solid #999;
      width: auto;
      display: block;
      height: 1px;
      margin: 0 -15px 10px;
      padding: 0 0 10px;
    }
  }

  table {
    width: 100%;
    border: 1px solid #99999947;
    text-align: center;

    thead {
      th {
        padding: 15px 10px;
        border: 1px solid #99999947;
        background-color: #3c3c3c;
      }
    }
    tbody {
      td {
        padding: 15px 10px;
        border: 1px solid #99999947;
        color: @textWhite;

        &.docNoText {
          font-weight: 500;
          color: @primary-color;
          cursor: pointer;
          transition: all 0.5s ease;

          &:hover {
            color: @secondary-color;
          }
        }

        .dateDownload {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .blueText {
          color: @secondary-color;
          font-weight: 500;
        }
      }
    }
    &.tracking {
      thead {
        th {
          background-color: @secondary-color;
          border: none;
        }
      }
      tbody {
        td {
          border: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .scroll {
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
    }
  }

  @media (max-width: 767px) {
    table {
      thead {
        display: none;
      }

      tbody,
      tr,
      td {
        display: block;
        position: relative;
      }

      tbody tr {
        &:nth-child(even) {
          background-color: #333;
          border-top: 3px solid #999;
          border-bottom: 3px solid #999;
        }

        td {
          text-align: right;
          padding: 10px 12px;
          min-height: 45px;
          color: @textWhite;

          .dateDownload {
            text-align: left;

            .date {
              width: 115px;
            }
          }

          &.hideInMob {
            display: none;
          }
        }
      }

      tbody tr td::before {
        content: attr(data-label);
        display: inline-block;
        text-align: left;
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 3;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
        color: @textWhite;
      }
    }

    .pagination {
      text-align: center;
      margin: 10px auto;
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        text-align: center;
      }
    }
  }
}

.centerDetail {
  table {
    width: 100%;
    max-width: 600px;
    border: 1px solid #99999947;
    border-radius: 10px;
    margin: 20px auto;
    text-align: left;
    tbody {
      th,
      td {
        padding: 15px 10px;
        border: 1px solid #99999947;
        border-left: none;
        color: @textWhite;
        word-break: break-all;
      }
      th {
        background: @secondary-color;
        color: #e5e5e5;
        font-weight: 500;
        font-size: 1rem;
        padding: 10px 15px;
        border-right: none;
        width: 160px;
      }
    }
    @media (max-width: 767px) {
      max-width: unset;
      tbody {
        th,
        td {
          width: 100%;
          display: block;
          border: none;

          text-align: left !important;
        }
        tr {
          border: none;
          &:nth-child(even) {
            background-color: transparent !important;
            border: none;
          }
        }
      }
    }
  }
}
