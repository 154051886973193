img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: @text-color;
}

.img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}

// html {
//   scroll-behavior: smooth;
// }
html {
  background-color: #000000;
}
body {
  font-family: @primary_font;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: #000000;
  width: 100%;
  // min-width: 360px;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eeeeee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    border: solid 2px #eee;
    border-radius: 5px;
    transition: border-color 400ms;

    &:hover {
      border-color: #9e9e9e;
      cursor: pointer;
    }
  }

  @media (max-width: 1560px) {
    font-size: 0.96rem;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.7);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.7);
}

::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: #000;
// }
.ant-form {
  font-size: 1rem;
}

a.ant-btn {
  display: inline-block;
  padding: 12px 20px !important;
}

a.ant-btn,
.ant-btn {
  min-width: 150px;
  min-height: 45px;
  padding: 12px 20px;
  font-size: 1rem;
  line-height: normal;
  color: #fff;
  text-shadow: none;
  box-shadow: none;
  border-radius: 50px;
  border: 1px solid #000;
  background-color: #000;
  transition: all 0.5s ease;

  &.ant-btn-icon-only.ant-btn-sm {
    min-width: unset;
    min-height: unset;
  }

  &.autoWidth {
    width: auto;
    min-width: unset;
  }

  &.autoHeight {
    height: auto;
    min-height: unset;
  }

  &.btn_black {
    background-color: #000;
    color: #fff;
  }

  &.ant-btn-error,
  &.ant-btn-primary,
  &.btn_primary {
    background-color: @primary-color;
    border-color: @primary-color;
    color: @textWhite;

    &:hover {
      background-color: #fff;
      color: @primary-color;
      border-color: @primary-color;
    }
  }

  &.ant-btn-default,
  &.ant-btn-secondary,
  &.btn_secondary {
    background-color: @secondary-color;
    border-color: @secondary-color;
    color: @textWhite;

    &:hover {
      background-color: #fff;
      color: @secondary-color;
      border-color: @secondary-color;
    }
  }
}

form {
  .btn_secondary,
  .btn_primary {
    min-width: 150px;
    min-height: 45px;
  }

  .btn_small {
    min-width: 100px;
    min-height: 35px;
    font-size: 14px;
  }
}

.radius50 {
  border-radius: 50px;
}

.sc_title {
  .main_title {
    margin-bottom: 1.75rem;
    position: relative;

    > * {
      font-size: 2.6rem;
      font-family: @title_font;
      font-weight: normal;
      letter-spacing: 2px;
      position: relative;
      padding-top: 1rem;
      color: @textWhite;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 4px;
        background-color: @primary-color;
        clip-path: polygon(0 0, calc(100% - 3px) 0%, 100% 100%, 3px 100%);
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0%;
        width: 15px;
        height: 4px;
        background-color: white;
        clip-path: polygon(0 0, calc(100% - 3px) 0%, 100% 100%, 3px 100%);
        animation: moveLine2 8s linear infinite;
      }
    }

    &::before {
      content: attr(data-title);
      position: absolute;
      left: 0;
      top: calc(50% + 1rem / 2);
      transform: translateY(-50%);
      font-size: 4.5rem;
      font-family: @secondary_font;
      font-weight: 500;
      z-index: 0;
      text-transform: uppercase;
      color: @PrimaryBlackColor;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-shadow: 0 0 1px rgba(255, 255, 255, 0.35);
    }
  }

  .sub_text {
    line-height: 1.6;

    p {
      margin-bottom: 1.5em;
    }

    &.max900 {
      max-width: 900px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.text-center {
    .main_title > *::before,
    .main_title > *::after {
      left: 50%;
      transform: translateX(-50%);
    }

    .main_title > *::after {
      animation: moveLine3 8s linear infinite;
    }

    .main_title::before {
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  @media (max-width: 1560px) {
    .main_title {
      > * {
        font-size: 2.25rem;

        &:before,
        &:after {
          height: 3px;
        }
      }

      &::before {
        font-size: 4.5rem;
      }
    }

    .sub_text {
      line-height: 1.5;
    }
  }

  @media (max-width: 1199px) {
    .main_title {
      .mb-2 {
        margin-bottom: 1.5rem;
      }

      &::before {
        font-size: 3.5rem;
        top: calc(50% + 0.25rem);
      }

      > * {
        font-size: 2rem;
        padding-top: 0.5rem;

        &:before,
        &:after {
          height: 2px;
        }
      }
    }

    .sub_text {
      p {
        margin-bottom: 1em;
      }
    }
  }

  @media (max-width: 991px) {
    &.sm_text-center .main_title {
      text-align: center;

      &::before {
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      > * {
        &::before,
        &::after {
          left: 50%;
          transform: translateX(-50%);
        }

        &:after {
          animation: moveLine3 8s linear infinite;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .text-center .main_title::before {
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .main_title {
      &.mb-2 {
        margin-bottom: 1.25rem;
      }

      &::before {
        font-size: 2.75rem;
      }

      > * {
        font-size: 1.75rem;
      }
    }
  }

  @media (max-width: 380px) {
    .main_title {
      &::before {
        font-size: 2.25rem;
      }

      > * {
        font-size: 1.5rem;
      }
    }
  }
}

.btn {
  &.btn_arrow {
    border-radius: 50px;
    padding: 5px;
    width: 45px;
    height: 45px;
    aspect-ratio: 1;
    background-color: rgba(0, 0, 0, 0);
    border: solid 2px #a59a9a;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    transition: border-color 400ms;
    cursor: pointer;

    .img {
      filter: invert(1) opacity(0.75);
      max-width: 40px;
      width: 100%;
      display: block;
      transition: filter 400ms;
      transition: filter 400ms, -webkit-filter 400ms;
    }

    &.right .img {
      transform: scaleX(-1);
    }

    &:hover {
      border-color: @secondary-color;

      .img {
        filter: invert(15%) sepia(44%) saturate(4609%) hue-rotate(233deg)
          brightness(90%) contrast(90%);
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1560px) {
    &.btn_arrow {
      width: 40px;
      height: 40px;
      border-width: 1px;
    }
  }
}
@media (max-width: 991px) {
  .otpBtn {
    text-align: left;
  }
}
@media (max-width: 767px) {
  .otpBtn {
    text-align: left;
    a.ant-btn,
    .ant-btn {
      min-width: unset;
      margin: auto;
    }
  }
}

.fullscreen__spinner__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__page__wrapper {
  flex-wrap: wrap;
  background: @PrimaryBlackColor;
  width: 100%;
  overflow-x: hidden;

  .ant-layout-header {
    width: 100%;
    height: auto;
    line-height: normal;
  }

  .main__page__content {
    width: 100% !important;

    &.inner > section {
      &:first-child {
        padding-top: 204px;

        @media (max-width: 1560px) {
          padding-top: 160px;
        }

        @media (max-width: 1199px) {
          padding-top: 140px;
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent;
}

.slick-slider .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  margin-top: 2rem;
  position: static;
  bottom: auto;

  li {
    list-style: none;

    button {
      margin: 0 10px;
      padding: 0;
      display: block;
      border: none;
      border-radius: 50px;
      width: 7px;
      height: 7px;
      background-color: #fff;
      font-size: 0px;
      cursor: pointer;
      outline: none;
      transition: background-color 400ms, -webkit-box-shadow 400ms;
      transition: box-shadow 400ms, background-color 400ms;
      transition: box-shadow 400ms, background-color 400ms,
        -webkit-box-shadow 400ms;
    }

    &.slick-active button {
      background-color: @primary-color;
      box-shadow: 0 0 0 5px #000, 0 0 0 6px @primary-color;
    }
  }
}

.videoModal {
  max-width: 90%;

  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close {
    top: 10px;
    right: 10px;

    .ant-modal-close-x {
      color: #fff;
      font-size: 18px;
      background-color: rgb(0 0 0 / 70%);
      width: 35px;
      height: 35px;
      line-height: 36px;
      border-radius: 50%;
    }
  }

  .ant-modal-content {
    border: 0 !important;
  }

  .ant-modal-body {
    padding: 0 !important;
    border: 5px solid @secondary-color;

    video {
      width: 100%;
      display: block;
    }
  }
}

@keyframes moveLine2 {
  0% {
    left: 0%;
  }

  50% {
    left: calc(100px - 15px);
  }

  100% {
    left: 0;
  }
}

@keyframes moveLine3 {
  0% {
    left: calc(50% - 100px / 2 + 15px / 2);
  }

  50% {
    left: calc(50% + 100px / 2 - 15px / 2);
  }

  100% {
    left: calc(50% - 100px / 2 + 15px / 2);
  }
}

@media (max-width: 1199px) {
  .pv-64 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .pv-48 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.ant-radio {
  .ant-radio-inner {
    background-color: transparent;
    border-color: #fff;

    &:after {
      width: 12px;
      height: 12px;
      margin-top: -6px;
      margin-left: -6px;
    }
  }

  &.ant-radio-checked .ant-radio-inner {
    border-color: #fff;
  }
}

.ant-notification-notice-message {
  color: #000;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
}

.ant-empty-small {
  color: rgb(255 255 255 / 25%);
}

.ant-empty-img-simple-path {
  fill: transparent;
}

.ant-empty-img-simple-ellipse {
  fill: transparent;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: transparent;
  box-shadow: none;
}

.noteText {
  font-size: 14px;
  margin-bottom: 30px;
  color: rgb(255 255 255 / 70%);

  strong {
    color: #fff;
  }
}

.ant-tooltip {
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgba(255, 255, 255, 0.85),
      rgba(255, 255, 255, 0.95)
    );
  }

  .ant-tooltip-inner {
    background-color: rgba(255, 255, 255, 0.95);
    color: #000;

    p span:first-child {
      font-weight: 500;
    }
  }
}

.fullPageBG {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    pointer-events: none;
    z-index: 0;
    background-color: #000;
  }
}

.ant-input-suffix {
  color: #000;
  line-height: normal;
}

.ant-input-affix-wrapper {
  padding: 0 10px 0 0;
  border-radius: 0;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: transparent;
  box-shadow: none;
}

.thankYou_page {
  .resultImg {
    text-align: center;
    margin-bottom: 80px;

    img {
      max-width: 360px;
      max-height: 180px;
    }

    h1 {
      font-size: 50px;
      color: @secondary-color;
    }
  }

  .resultInfo {
    border: 1px solid #3c3c3c;
    padding: 60px 30px 30px;
    text-align: center;
    position: relative;
    max-width: 760px;
    margin: 0 auto;

    .resultTitle {
      background-color: @secondary-color;
      padding: 10px 20px;
      display: inline-block;
      font-size: 16px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);

      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid @secondary-color;
        position: absolute;
        top: 0;
        left: -19px;
      }

      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid @secondary-color;
        position: absolute;
        top: 0;
        right: -19px;
      }
    }

    .resultContent p {
      font-size: 18px;
      color: #fff;
      text-align: center;
    }

    .btnSec button {
      margin: 10px 10px 0;
    }
  }

  .resultBG {
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }
}

.pagination {
  .ant-pagination {
    margin: 20px 0;
    text-align: right;

    li {
      &.ant-pagination-item {
        background: transparent;
        border: 0;

        a {
          color: @white;
          font-size: 14px;
          &:hover,
          &:focus {
            color: @primary-color;
          }
        }

        &.ant-pagination-item-active a {
          color: @primary-color;
        }
      }

      &.ant-pagination-prev,
      &.ant-pagination-next {
        font-size: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 1px;

        .anticon {
          font-size: 14px;
          svg path {
            fill: @white;
          }
          &:hover,
          &:focus {
            svg path {
              fill: @primary-color;
            }
          }
        }
      }

      .ant-pagination-item-link {
        .ant-pagination-item-link-icon {
          svg path {
            fill: @primary-color;
          }
        }
        .ant-pagination-item-ellipsis {
          color: @white;
        }
      }
    }
  }
}

.input_group .tooltipLabel svg {
  width: 16px;
  height: 16px;

  path {
    stroke: #fff;
  }
}

.cursorPointer {
  cursor: pointer;
}

.customScrollbars {
  .track-vertical {
    top: 0;
    right: 0;
    height: 100%;
    .thumb-vertical {
      background-color: @textWhite;
      width: 5px !important;
      right: 0px;
      border-radius: 10px;
    }
  }
}
.or {
  display: block;
  margin: 20px auto 10px;
  text-align: center;
  width: 100%;
  span {
    width: 50px;
    height: 50px;
    padding: 10px;
    font-size: 1rem;
    line-height: normal;
    background-color: @primary-color;
    color: @textWhite;
    text-shadow: none;
    box-shadow: none;
    border-radius: 50px;
    transition: all 0.5s ease;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 9;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%);
      width: 200px;
      height: 1px;
      background-color: @primary-color;
      z-index: -1;
    }
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
}

.input_group_number {
  .ant-input-affix-wrapper {
    background-color: transparent;
    border: none;
    padding: 0;
    .ant-input-suffix {
      position: absolute;
      right: 0;
      top: 11px;
      .ant-input-clear-icon {
        font-size: 20px;
        color: @primary-color;
      }
    }
  }
  @media (max-width: 1560px) {
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        top: 8px;
        .ant-input-clear-icon {
          font-size: 16px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .ant-input-affix-wrapper {
      .ant-input-suffix {
        top: 7px;
        .ant-input-clear-icon {
          font-size: 16px;
        }
      }
    }
  }
}

@custom-error-color: #ff4d4f;

.bookingDateSelect {
  .ant-form-item {
    margin-bottom: 0;
  }

  // Mixin for error styling
  .errorStyles() {
    border-bottom: 1px solid @custom-error-color !important;
  }

  .ant-form-item-has-error {
    .ant-input {
      .errorStyles();
    }
    .ant-picker {
      .errorStyles();
    }
  }
}
