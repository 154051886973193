@primary-color: #ed1c24;
@secondary-color: #2e3192;
@secondary-dark-color: #161969;
@secondary-lite-color: #4a4ec9;

@gutter: 15px;
@font-family: "Montserrat", sans-serif;
@text-color: #ffffff;
@heading-color: @text-color;
@border-radius-base: 4px;
@blackColor: #0d183b;

// Main Header Variables
@layout-body-background: @white;
@layout-content-background: #f0f2f5;
@layout-header-background: @white;
@layout-header-height: 64px;
@layout-header-padding: 0 0;
@layout-header-color: @text-color;
@layout-sidebar-background: #263238;

// Balck Colors Shades Theme
@FullBlackColor: #000;
@PrimaryBlackColor: #191919;
@SecondaryBlackColor: #222222;
@border: 1px solid rgba(255, 255, 255, 0.5);
@borderColor: rgba(255, 255, 255, 0.5);

@textWhite: rgba(255, 255, 255, 0.9);

// Main Header Logo Variables
@logo-height: 42px;
@logo-text-size: 18px;
@logo-space: 10px;

// Section Variables
@section-padding: 50px;

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "impact";
  src: //   url("../fonts/Impact.eot?") format("embedded-opentype")
    url("../fonts/Impact.woff2") format("woff2"),
    url("../fonts/Impact.woff") format("woff"),
    url("../fonts/Impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@primary_font: "Fira Sans", "Trebuchet MS", "Lucida Sans Unicode",
  "Lucida Grande", "Lucida Sans", Arial, sans-serif;
@secondary_font: "Poppins", "Trebuchet MS", "Lucida Sans Unicode",
  "Lucida Grande", "Lucida Sans", Arial, sans-serif;
@title_font: "impact", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
  "Lucida Sans", Arial, sans-serif;
