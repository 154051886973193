.contactUs_section {
  .contactUs_form {
    padding-top: 2em;
  }

  .officeAddressWrap {
    margin-top: 3em;
    background-color: #fff;
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5em;
    padding: 1.75em;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 35%;
      height: 3px;
      background-color: @primary-color;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: @primary-color;
    }

    .col_custom {
      display: grid;
      grid-template-columns: 46px 1fr;
      //   align-items: center;
      padding: 0;
      gap: 1em;

      .icon_wrap {
        border: dashed 1px #878787;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
        }

        .home_icon {
          width: 27px;
          height: 25px;
          background-position: -5px -5px;
        }

        .call_icon {
          width: 20px;
          height: 20px;
          background-position: -42px -5px;
        }

        .mail_icon {
          width: 22px;
          height: 17px;
          background-position: -5px -40px;
        }
      }

      .content_wrap {
        h3 {
          font-size: 1.25em;
          font-weight: 500;
          color: @secondary-color;
          margin-bottom: 0.35em;
        }

        p,
        a {
          color: #000;
          font-weight: 500;
          line-height: 1.5em;
        }

        a:hover {
          color: @primary-color;
        }
      }

      &.row2 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        row-gap: 2em;

        .col_sub {
          display: grid;
          grid-template-columns: 45px 1fr;
          //   align-items: center;
          padding: 0;
          gap: 1em;

          .content_wrap {
            h3 {
              font-size: 1rem;
            }

            p,
            a {
              font-size: 0.925em;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .officeAddressWrap {
      grid-template-columns: repeat(2, 1fr);

      .col_custom {
        &.row2 {
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(1, auto);
          grid-column: span 2;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .officeAddressWrap {
      grid-template-columns: 1fr;

      &::before {
        width: 50%;
      }

      &::after {
        height: 50%;
      }

      .col_custom {
        &.row2 {
          grid-template-columns: 1fr;
          grid-template-rows: unset;
          grid-column: unset;
          row-gap: 1.5em;
        }
      }
    }
  }
}

.captchaWrap {
  > .ant-row {
    margin-bottom: 20px;
    align-items: center;
  }

  .captchaCode {
    margin: 0;

    input {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 2px;
    }

    .ant-form-item-row {
      flex-flow: unset;
      align-items: center;

      .ant-form-item-label {
        max-width: 100px;
        width: 100%;
        line-height: normal;
        padding: 0;
      }
    }
  }

  .resetCaptcha {
    background-color: transparent;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    min-width: unset;
    min-height: unset;
    background-color: transparent;

    svg,
    .img {
      width: 28px;
      height: 28px;
      transform: rotate(-90deg);
    }
  }
  svg.resetCaptcha {
    width: 28px;
    height: 28px;
    transform: rotate(-90deg);
    fill: @textWhite;
    transition: fill 0.4s ease-in-out;
    &:hover,
    &:focus-within {
      fill: @primary-color;
    }
  }
}
