.error404 {
	.error404_wrap {
		background-color: @secondary-color;
		border-radius: 10px;
		padding: 2em;
		max-width: 1200px;
		margin: 0 auto;
		animation-name: fadeInForUpText;
		animation-duration: 1000ms;
		.img {
			display: block;
			margin: auto;
		}
		.content {
			margin-top: 2em;
			margin-left: auto;
			margin-right: auto;
			max-width: 800px;
			font-family: @secondary_font;
			text-align: center;
			p {
				margin-bottom: 0.75em;
				text-align: center;
			}
			.btn {
				margin-top: 0.5em;
				padding: 0.5em 1.5em;
				display: inline-block;
			}
		}
	}
	.beforeImg,
	.afterImg {
		position: absolute;
		top: 0;
		left: 0%;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center left;
		background-size: auto;
		z-index: -1;
	}
	.afterImg {
		background-position: center right;
	}

	.item_wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	@media (max-width: 1199px) {
		.error404_wrap {
			max-width: 620px;
		}
	}

	@media (max-width: 767px) {
		.error404_wrap {
			max-width: 460px;
			.content {
				font-size: 13px;
				margin-top: 1em;
			}
		}
	}
}

.unauthorized__section {
	text-align: center;
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	font-size: 16px;
	line-height: 1.3;
	padding: 0 20px 60px;
	.errorImage {
		width: 80%;
		max-width: 733px;
		margin: 40px auto;
		position: relative;
		img {
			width: 100%;
			height: auto;
			object-fit: contain;
		}
		span {
			position: absolute;
			top: 30%;
			right: 99px;
			z-index: 5;
			width: 200px;
			transform: rotate(-6deg) skew(-2deg, 0deg);
			color: @secondary-color;
			&.title {
				font-size: 40px;
				font-weight: 600;
			}
			&.text {
				font-size: 20px;
				margin-top: 45px;
				margin-right: -5px;
			}
		}
		@media (max-width: 1000px) {
			width: 600px;
			span {
				right: 60px;
				&.title {
					font-size: 30px;
				}
				&.text {
					font-size: 20px;
					margin-top: 35px;
				}
			}
		}
		@media (max-width: 768px) {
			width: 400px;
			span {
				width: 130px;
				right: 45px;
				&.title {
					font-size: 20px;
				}
				&.text {
					font-size: 14px;
					margin-top: 25px;
				}
			}
		}
		@media (max-width: 430px) {
			width: 300px;
			span {
				width: 90px;
				right: 38px;
				&.title {
					font-size: 18px;
				}
				&.text {
					font-size: 10px;
					margin-top: 20px;
				}
			}
		}
	}
	.errorTitle {
		font-size: 28px;
		font-weight: 600;
		line-height: 1.3;
		margin: 10px auto 20px;
	}
	p {
		font-size: 16px;
		line-height: 1.5;
		margin: 10px auto 20px;
		text-align: center;
	}
	// a.ant-btn {
	// 	&.ant-btn-error {
	// 		padding: 10px 30px !important;
	// 		line-height: 1.3;
	// 		background-color: @primary-color;
	// 		color: #fff;
	// 		border: none;
	// 		text-shadow: none;
	// 		transition: background-color 0.5s ease-in-out;
	// 		&:hover,
	// 		&:focus-within {
	// 			background-color: @secondary-color;
	// 		}
	// 	}
	// }
}

.innerFullBanner {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: relative;
	height: 100vh;
	min-height: 900px;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.7;
		pointer-events: none;
		z-index: 1;
		background-color: #000;
	}
	> div {
		z-index: 1;
		position: relative;
	}

	@media (max-width: 1560px) {
		min-height: 600px;
	}

	@media (max-width: 991px) {
		min-height: unset;
		height: 600px;
	}

	@media (max-width: 767px) {
		height: auto;
	}
}
