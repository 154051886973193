.container,
.container_sm {
  max-width: 100%;
  width: 100%;
  padding-right: @gutter;
  padding-left: @gutter;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

// .container {
// 	@media (min-width: @screen-md) {
// 		max-width: @screen-md;
// 	}
// 	@media (min-width: @screen-lg) {
// 		max-width: @screen-lg;
// 	}
// 	@media (min-width: @screen-xl) {
// 		max-width: @screen-xl;
// 	}
// 	@media (min-width: @screen-xxl) {
// 		max-width: @screen-xxl;
// 	}
// }

.container {
  // @media (min-width: 576px) {
  // 	max-width: 540px;
  // }
  // @media (min-width: 768px) {
  // 	max-width: 720px;
  // }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1199px) {
    max-width: 1140px;
  }
  @media (min-width: 1440px) {
    max-width: 1260px;
  }
  @media (min-width: 1600px) {
    max-width: 1560px;
  }
  @media (min-width: 1800px) {
    max-width: 1690px;
  }
}

.container_sm {
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1280px) {
    max-width: 1100px;
  }
  @media (min-width: 1600px) {
    max-width: 1200px;
  }
}
