.ant-modal-root {
  .ant-modal-mask {
    background-color: rgb(0 0 0 / 80%);
  }

  .ant-modal {
    &.primaryModal {
      .ant-modal-content {
        background-color: @PrimaryBlackColor;
        border-radius: 8px;
        border-bottom: 5px solid @secondary-color;
        box-shadow: 0px 0px 47px -5px rgb(0 0 0 / 75%);

        .input_group_blk .input_group {
          margin-bottom: 2rem;

          .ant-input {
            background-color: transparent;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 0 20px;
            height: 50px;
            font-size: 1.3rem;
            color: #000;
          }
        }

        .ant-modal-close {
          top: -15px;
          right: -15px;

          .ant-modal-close-x {
            background-color: #fff;
            color: @primary-color;
            border: 2px solid @secondary-color;
            font-size: 11px;
            border-radius: 50%;
            //   box-shadow: 0px 0px 47px -5px rgb(0 0 0 / 75%);
            width: 40px;
            height: 40px;
            line-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-modal-close-icon {
              border: 2px solid @primary-color;
              border-radius: 50%;
              padding: 3px;
            }
          }
        }
      }

      .ant-modal-header {
        background: #fff;
        border: 0;
        border-radius: 8px;
      }

      .ant-modal-body {
        padding: 0;
        font-family: @primary_font;
        font-size: 1rem;
        line-height: 1.6;

        form {
          max-width: 80%;
          margin: 0 auto;
        }
      }

      .ant-modal-footer {
        border: 0;
        text-align: left;
        .buttonGroup {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          .ant-form-item {
            margin: 0;
          }
        }
      }

      .ant-modal-title {
        color: @primary-color;
        text-align: center;
        font-size: 22px;
      }

      &.loginModal {
        .ant-modal-close {
          .ant-modal-close-x {
            background-color: @PrimaryBlackColor;
            border: 2px solid @textWhite;
            color: @textWhite;
            width: 30px;
            height: 30px;

            .ant-modal-close-icon {
              border: none;

              svg {
                width: 15px;
                height: 15px;
              }
            }

            &:hover,
            &:focus-within {
              border-color: @primary-color;
              color: @primary-color;
            }
          }
        }

        .ant-modal-body {
          padding: 0;

          .modalHeaderLogo {
            text-align: center;
            padding: 20px 0 0;

            svg {
              max-width: 200px;
              height: auto;
            }
          }

          .loginModalWrap {
            color: @textWhite;
            padding: 30px 0;

            .ant-form-item-label label {
              color: @textWhite !important;
            }

            .title {
              padding: 0;
              text-align: center;
              font-family: @title_font;
              font-size: 40px;
              line-height: 1.3;
              position: relative;
              z-index: 3;

              .mainTitle {
                font-family: @title_font;
                font-size: 40px;
                line-height: 1.3;
                margin: 0;
              }

              &::after {
                content: attr(data-title);
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: -1;
                transform: translate(-50%, -50%);
                font-size: 200%;
                font-family: @secondary_font;
                font-weight: 700;
                text-transform: uppercase;
                color: @PrimaryBlackColor;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-shadow: 0 0 1px rgba(255, 255, 255, 0.35);
              }
            }

            .loginTypes {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              gap: 40px;
              width: 100%;
              margin: 10px auto;
              padding: 0;
              list-style: none;

              li {
                margin: 0;
                padding: 0;
                text-align: center;
                cursor: pointer;

                span {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  width: 64px;
                  height: 64px;
                  background-color: #3e3e3e;
                  border: 2px solid @textWhite;
                  border-radius: 64px;
                  margin: auto;

                  svg {
                    width: 36px;
                    height: 36px;
                  }
                }

                b {
                  display: block;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 1.3;
                  padding: 10px 0;
                }

                &.active,
                &:hover,
                &:focus-within {
                  span {
                    background-color: @primary-color;
                  }
                }
              }
            }

            .clientLoginForm {
              border-top: 1px solid #3d3d3d;
              padding-top: 25px;
              margin-top: 0px;

              .ant-form {
                .input_group {
                  padding-bottom: 10px;

                  .ant-form-item {
                    margin-bottom: 13px;

                    .ant-form-item-label label {
                      color: @textWhite !important;
                    }

                    input,
                    .ant-input {
                      background-color: @PrimaryBlackColor;
                      color: @textWhite !important;
                      border-radius: 0;
                      border: none;
                      border-bottom: 1px solid #3d3d3d;

                      &:autofill,
                      &:-internal-autofill-selected {
                        color: @textWhite !important;
                        text-fill-color: @textWhite;
                        box-shadow: 0 0 0 1000px @PrimaryBlackColor inset;
                        -webkit-text-fill-color: @textWhite;
                        -webkit-box-shadow: 0 0 0 1000px @PrimaryBlackColor
                          inset;
                        transition: background-color 5000s ease-in-out 0s;
                      }

                      &:hover,
                      &:focus,
                      &:focus-within {
                        border-color: #d9d9d9;
                        outline: none;
                        color: @textWhite !important;
                        box-shadow: inset 300px 300px 0 @PrimaryBlackColor !important;
                      }
                    }
                  }

                  .ant-input-affix-wrapper {
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #3d3d3d;
                    background-color: @PrimaryBlackColor;

                    > input.ant-input {
                      padding: 4px 11px;
                      border: none;
                    }

                    .ant-input-suffix .ant-input-password-icon.anticon {
                      color: #757575;
                    }

                    &:hover,
                    &:focus,
                    &:focus-within {
                      border-color: #d9d9d9;
                      outline: none;
                      box-shadow: none;
                    }
                  }
                }

                .captchaWrap {
                  .ant-row {
                    margin-bottom: 3px;

                    .resetCaptcha {
                      background-color: transparent;
                      padding: 0;
                      margin: 0;
                      min-width: unset;
                      min-height: unset;
                      background-color: transparent;

                      svg.resetCaptcha {
                        width: 24px;
                        height: 24px;
                        transform: rotate(-90deg);
                        fill: @textWhite;
                        transition: fill 0.4s ease-in-out;
                        &:hover,
                        &:focus-within {
                          fill: @primary-color;
                        }
                      }
                    }

                    .captchaCode input {
                      color: @textWhite;
                    }
                  }
                }
              }
            }
          }

          .modalBG {
            height: 60px;
            margin-top: 10px;
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        @media (max-width: 768px) {
          .ant-modal-body {
            .loginModalWrap {
              .loginTypes {
                gap: 10px;

                li {
                  span {
                    width: 50px;
                    height: 50px;

                    svg {
                      width: 30px;
                      height: 30px;
                    }
                  }

                  b {
                    padding: 10px 15px;
                  }
                }
              }
            }
          }
        }
      }

      @media (max-width: 768px) {
        max-width: calc(100vw - 30px);
        margin: 8px auto;

        .ant-modal-content {
          .ant-modal-close {
            top: -10px;
            right: -10px;
          }
          .ant-modal-footer {
            .buttonGroup {
              flex-direction: column;
            }
          }
        }
      }

      &.termsAndConditions {
        width: 80% !important;
        max-width: 900px;

        .fixedContent {
          // max-height: 50dvh;
          overflow-y: auto;
          width: 90%;
          margin: 20px auto;
          padding: 0 40px 0 0;
          text-align: justify;
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          li {
            margin: 5px 0 10px;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-weight: 600;
            color: @secondary-lite-color;
          }
          h2 {
            font-size: 1.6rem;
          }
          h3 {
            font-size: 1.2rem;
          }
          h4 {
            font-size: 1.1rem;
            margin-bottom: 5px;
          }

          ul {
            list-style: disc;
            margin-left: 20px;
            li {
              font-weight: 400;
              color: @textWhite;
            }
          }
          ol.disclaimer li::marker {
            font-weight: 600;
            font-size: 1.1rem;
            color: @secondary-lite-color;
          }
          a {
            font-weight: 400;
            color: @primary-color;
          }
          p {
            font-weight: 400;
            color: fade(@textWhite, 75%);
          }
        }
      }
    }
  }
}
