.eBook_section {
  .step_wrapper {
    .ant-steps-item {
      .ant-steps-item-icon {
        display: none;
      }
    }

    .ant-steps {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 4rem;

      --box-width: 200px;
      --icon-size: 75px;
      --line-space: 10px;
      .ant-steps-item {
        width: var(--box-width);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .ant-steps-item-container {
          .ant-steps-item-tail {
            display: none;
          }
        }
      }
      .ant-steps-item-content {
        width: var(--box-width);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        .ant-steps-item-title::after {
          display: none;
        }
        .icon {
          cursor: pointer;
          width: var(--icon-size);
          height: var(--icon-size);
          border-radius: var(--icon-size);
          background-color: #3d3d3d;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: calc(var(--icon-size) / 2.5) auto;
          box-shadow: 0 0 0 2px #fff;
          position: relative;
          transition: background-color 400ms;
          &:hover {
            background-color: #222;
          }
          &::before {
            content: "";
            position: absolute;
            top: calc(var(--line-space) / -1);
            left: calc(var(--line-space) / -1);
            width: calc(var(--icon-size) + var(--line-space) * 2);
            height: calc(var(--icon-size) + var(--line-space) * 2);
            border-radius: var(--icon-size);
            border: solid 2px #3d3d3d;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            pointer-events: none;
            animation: circleMove 5s linear infinite;
            transition: border-color 400ms;
          }
          &::after {
            content: "";
            position: absolute;
            top: calc(var(--line-space) * 2 / -1);
            left: calc(var(--line-space) * 2 / -1);
            width: calc(var(--icon-size) + var(--line-space) * 2 * 2);
            height: calc(var(--icon-size) + var(--line-space) * 2 * 2);
            border-radius: var(--icon-size);
            border: solid 2px #3d3d3d;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            pointer-events: none;
            animation: circleMove 5.25s linear infinite;
            transition: border-color 400ms;
          }
          @keyframes circleMove {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          .img,
          .rightTic {
            width: calc(var(--icon-size) / 1.5);
            height: calc(var(--icon-size) / 2);
            display: block;
            filter: invert(1);
          }
          .arrow {
            position: absolute;
            width: calc(var(--box-width) / 2 - var(--icon-size) / 2);
            height: auto;
            aspect-ratio: 1;
            left: 200%;
            // right: calc(var(--box-width) / -2 + var(--icon-size) / 10);
            filter: brightness(0) saturate(100%) invert(22%) sepia(11%)
              saturate(4%) hue-rotate(355deg) brightness(98%) contrast(94%);
            transition: filter 400ms;
          }
          .rightTic {
            display: none;
          }
        }
        .title {
          color: #ccc;
        }
      }

      .ant-steps-item-active {
        .ant-steps-item-content {
          .icon {
            background-color: @primary-color;
            &:before,
            &:after {
              border-color: @primary-color;
            }
            .arrow {
              filter: brightness(0) saturate(100%) invert(39%) sepia(20%)
                saturate(7072%) hue-rotate(2deg) brightness(103%) contrast(105%);
            }
          }
        }
      }

      .ant-steps-item-finish {
        .ant-steps-item-content {
          .icon {
            .img {
              display: none;
            }
            .rightTic {
              display: block;
            }
          }
        }
      }

      @media (max-width: 1279px) {
        .ant-steps-item-content {
          .icon {
            .arrow {
              width: 40px;
              left: 180%;
            }
          }
        }
      }
      @media (max-width: 991px) {
        .ant-steps-item-content {
          .icon {
            .arrow {
              display: none;
            }
          }
        }
      }
      @media (max-width: 767px) {
        --box-width: 150px;
        --icon-size: 50px;
        --line-space: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: start;

        .ant-steps-item {
          padding: 0;
          white-space: break-spaces;
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            padding: 0 16px;
          }
          .icon {
            .arrow {
              display: none;
            }
          }
          .title {
            line-height: 1.3;
            padding: 10px;
          }
        }
      }
      @media (max-width: 350px) {
        --box-width: 130px;
        --icon-size: 40px;
        --line-space: 10px;
      }
    }
    .chargeNote {
      margin-bottom: 40px;
      label {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
        display: block;
      }
      .selectType {
        cursor: pointer;
        color: @primary-color;
        border-bottom: 1px solid @primary-color;
      }
    }

    .serviceType_wrap {
      margin-bottom: 40px;
      .ant-radio-group {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
        .ant-radio-wrapper {
          padding-left: 15px;
          padding-right: 15px;
          margin: 0;
          width: 33.33%;
          .ant-radio {
            display: none;
            + * {
              padding: 0;
              width: 100%;
            }
          }
          .col_wrap {
            background-color: #2d2d2d;
            border-radius: 6px;
            border-bottom: solid 5px #fff;
            padding: 1.5em;
            display: grid;
            align-items: center;
            row-gap: 1em;
            grid-template-columns: repeat(2, 1fr);
            transition: -webkit-box-shadow 400ms;
            transition: box-shadow 400ms;
            transition: box-shadow 400ms, -webkit-box-shadow 400ms;
            .icon .img {
              max-width: 50px;
              max-height: 50px;
              width: 100%;
              height: 100%;
              -webkit-filter: invert(1);
              filter: invert(1);
            }
            .number {
              text-align: right;
              > * {
                font-size: 2rem;
                font-weight: 400;
                white-space: nowrap;
              }
            }
            .sub_text {
              grid-column: span 2;
              color: rgba(255, 255, 255, 0.8);
              font-size: 1.125em;
              p {
                margin: 0;
                line-height: normal;
              }
            }
          }
          &.ant-radio-wrapper-checked {
            .col_wrap {
              border-bottom-color: @secondary-color;
            }
          }
        }
      }
      @media (max-width: 1400px) {
        .ant-radio-group {
          .ant-radio-wrapper {
            .col_wrap {
              min-height: 160px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        .ant-radio-group {
          .ant-radio-wrapper {
            width: 100%;
            margin-bottom: 15px;
            .col_wrap {
              min-height: unset;
            }
          }
        }
      }
    }

    .step_content {
      h4 {
        font-size: 1.5rem;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }
      .col_address {
        .addressPickup {
          width: 100%;
          // overflow-x: auto;
          //   ul {
          //     list-style: none;
          //     padding: 0;
          //     margin: 0;
          //     display: flex;
          //     padding-bottom: 10px;
          //     li {
          //       list-style: none;
          //       border: solid 1px #3e3e3e;
          //       min-width: 512px;
          //       max-width: 512px;
          //       width: 100%;
          //       margin-right: 2em;
          //       .title {
          //         padding: 0.5em 1em;
          //         font-size: 1.25em;
          //         font-weight: 500;
          //         color: #000;
          //         background-color: #ccc;
          //       }
          //       .content {
          //         padding: 1em;
          //         address {
          //           color: rgba(255, 255, 255, 0.9);
          //         }
          //         p {
          //           margin-bottom: 0.5em;
          //           color: rgba(255, 255, 255, 0.9);
          //         }
          //       }
          //     }
          //   }
          > div > div {
            &:first-child {
              max-height: 100% !important;
            }
            &:nth-child(2) > div {
              background-color: @primary-color !important;
            }
          }
          .ant-radio-group {
            padding: 0 0 10px;
            margin: 0;
            width: 100%;
            .cpListBox {
              list-style: none;
              // min-width: 512px;
              // max-width: 512px;
              width: 100%;
              height: 100%;
              // margin-right: 2rem;
              label {
                width: 100%;
                height: 100%;
                display: block;
                border: solid 1px #3e3e3e;
                transition: all 0.5s ease;
                .ant-radio {
                  display: none;
                  + * {
                    padding: 0;
                  }
                }
              }
              .title {
                padding: 0.5em 1em;
                font-size: 1.25em;
                font-weight: 500;
                color: #000;
                background-color: #ccc;
                transition: all 0.5s ease;
              }
              .content {
                padding: 1em;
                address {
                  color: rgba(255, 255, 255, 0.9);
                  word-break: break-word;
                }
                p {
                  margin-bottom: 0.5em;
                  color: rgba(255, 255, 255, 0.9);
                }
              }
              .ant-radio-wrapper-checked {
                border-color: @secondary-color;
                .title {
                  color: #fff;
                  background-color: @secondary-color;
                }
              }
            }
            .cPValue_slick {
              // width: 85%;
              width: 100%;

              .slick-track {
                display: flex;
                &::before {
                  display: none;
                }

                .slick-slide {
                  height: auto;
                  padding: 10px;
                  position: relative;

                  & > div {
                    height: 100%;
                    //   background-color: #fff;
                    border-radius: 0;
                    overflow: visible;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
      .prevBtnWrap {
        display: flex;
        align-items: center;
        .prevBtn {
          min-width: unset;
          background: transparent;
          border: 0;
          padding: 0;
          padding-bottom: 2px;
          font-weight: 500;
          color: @primary-color;
          border: none;
          border-radius: 0;
          transition: all 0.5s ease;
          &:hover {
            color: @secondary-color;
            svg path {
              fill: @secondary-color;
            }
          }
          svg {
            width: 22px;
            margin-right: 6px;
            path {
              fill: @primary-color;
              transition: all 0.5s ease;
            }
          }
        }
      }
      // @media (max-width: 767px) {
      //   .col_address {
      //     .addressPickup {
      //       .ant-radio-group .cpListBox {
      //         min-width: 260px;
      //         max-width: 260px;
      //       }
      //     }
      //   }
      // }
      // @media (max-width: 350px) {
      //   .col_address {
      //     .addressPickup {
      //       .ant-radio-group .cpListBox {
      //         min-width: 260px;
      //         max-width: 260px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
.dropoffNote {
  font-size: 18px;
  font-weight: 600;
  color: @primary-color;
  margin-bottom: 30px;
}

.summaryWrap {
  .mainBox {
    border-radius: 15px;
    border: 2px solid @SecondaryBlackColor;
    margin: 0 0 35px;
    padding: 35px 25px;
    .dataSection {
      border-radius: 10px;
      border: 1px solid @borderColor;
      margin: 0 0 55px;
      padding: 20px;
      position: relative;
      &:last-child {
        margin: 0;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        width: calc(100% - 40px);
        position: absolute;
        top: -20px;
        strong {
          background-color: @PrimaryBlackColor;
          padding: 10px;
          margin-left: -10px;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          svg {
            width: 26px;
            height: auto;
            path,
            circle {
              fill: @borderColor;
            }
          }
        }
        .icon {
          background-color: @PrimaryBlackColor;
          display: inline-block;
          width: 40px;
          height: 40px;
          cursor: pointer;
          padding: 10px;
          path {
            fill: @borderColor;
          }
        }
      }
      .details {
        font-family: @primary_font;
        font-size: 15px;
        line-height: 20px;
        color: @borderColor;
        font-weight: 400;
        padding: 0;
        margin: 10px 0 0;
        p {
          margin: 0 0 6px;
        }
        .sectionTitle {
          color: @textWhite;
          word-break: break-all;
        }

        .sep {
          text-align: center;
          position: relative;
          z-index: 5;
          .box {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background-color: @PrimaryBlackColor;
            border: 1px dashed @borderColor;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 0;
            padding: 10px;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: @borderColor;
              }
            }
            &::after {
              content: "";
              width: 90%;
              height: 1px;
              background-color: @borderColor;
              display: block;
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: -1;
              transform: translate(-50%, -50%);
            }
          }
        }

        table {
          &.orderDetails {
            width: 100%;
            border-collapse: collapse;
            thead {
              border-radius: 10px;
              overflow: hidden;
              tr {
                border-radius: 10px;
                th {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 1.2;
                  color: @textWhite;
                  text-align: center;
                  padding: 8px 15px;
                  background-color: @SecondaryBlackColor;
                  &:first-child {
                    text-align: left;
                    border-radius: 10px 0 0 10px;
                  }
                  &:last-child {
                    border-radius: 0 10px 10px 0;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 1.2;
                  color: @textWhite;
                  text-align: center;
                  padding: 8px 15px;
                  &:first-child {
                    text-align: left;
                  }
                }
              }
            }

            &.ebooking {
              thead,
              tbody {
                th,
                td {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .paymentSection {
      .title {
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
        color: @textWhite;
        border-bottom: 2px solid @SecondaryBlackColor;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .priceSummary {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.8;
          color: @borderColor;
          &:last-child {
            color: @textWhite;
            font-weight: 600;
            font-size: 17px;
            border-top: 2px solid @SecondaryBlackColor;
            margin-top: 5px;
            padding-top: 5px;
          }
        }
        &.pendingPayment {
          li {
            &.highlight,
            &:last-child {
              color: @textWhite;
              font-weight: 600;
              font-size: 17px;
              border-top: 2px solid @SecondaryBlackColor;
              margin-top: 5px;
              padding-top: 5px;
            }
            &.highlight {
              border-top: none;
              &.borderTop {
                border-top: 2px solid @SecondaryBlackColor;
              }
            }
          }
        }
      }
      .note {
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        line-height: 1.4;
        color: @borderColor;
        margin: 15px 0 0;
      }
    }
  }

  .gstForm {
    margin: -10px 0 35px;
    padding: 0 25px;
    .input_group {
      .ant-form-item {
        margin: 0 0 10px;
        .ant-input {
          background-color: transparent;
          border: 1px solid @borderColor;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          padding: 12px 20px;
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .mainBox {
      padding: 30px 10px;
      margin: 0 0 20px;
      .dataSection {
        padding: 25px 15px 15px;
        .header {
          align-items: flex-start;
          width: calc(100% - 10px);
          strong {
            word-break: break-all;
            align-items: flex-start;
            padding: 10px 5px;
          }
          .icon {
            padding: 5px;
            width: 35px;
            height: 35px;
            padding-left: 8px;
          }
        }
        .details {
          table.orderDetails tbody tr td {
            padding: 8px 10px;
          }
          .sep {
            margin: 15px 0 20px;
          }
        }
      }
    }
    .gstForm {
      border-bottom: 3px solid @SecondaryBlackColor;
      padding-bottom: 30px;
      .input_group {
        text-align: center;
      }
    }
  }
}
