.ant-dropdown {
	&.primaryDropdown {
		.isLoginItems {
			margin: 0;
			padding: 0;
			background-color: @SecondaryBlackColor;
			border: 2px solid @SecondaryBlackColor;
			.ant-dropdown-menu-item-active,
			.ant-dropdown-menu-item {
				padding: 0;
				background-color: transparent;
				.ant-dropdown-menu-title-content {
					padding: 0;
					display: block;
					a,
					span {
						padding: 10px 20px;
						display: block;
					}
				}
				&:hover,
				&:focus-within {
					background-color: @PrimaryBlackColor;
				}
			}
		}
	}
}
