.main__page__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: transparent;
  -webkit-transition: background-color 400ms, -webkit-box-shadow 400ms;
  transition: background-color 400ms, -webkit-box-shadow 400ms;
  transition: background-color 400ms, box-shadow 400ms;
  transition: background-color 400ms, box-shadow 400ms, -webkit-box-shadow 400ms;

  &.fixed {
    background-color: white;
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);

    .header_wraper {
      padding-block: 0.75rem;

      .logo_wrapper a img,
      .logo_wrapper a svg {
        max-height: 80px;
        -webkit-filter: none;
        filter: none;
      }

      .contact_wrap {
        ul li {
          .icon {
            -webkit-filter: invert(1);
            filter: invert(1);
          }

          a {
            color: #000;
          }
        }

        .menu_icon .menu_click .img {
          -webkit-filter: invert(0);
          filter: invert(0);
        }

        .login_icon {
          border-color: #000;

          .login_click {
            color: #000;

            svg {
              filter: invert(0);
            }
          }
        }
      }
    }
  }

  .header_wraper {
    padding: 1.25rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo_wrapper {
      img,
      svg {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
        display: block;
        width: auto;
        max-height: 100px;
        -webkit-transition: max-height 400ms, -webkit-filter 400ms;
        transition: max-height 400ms, -webkit-filter 400ms;
        transition: filter 400ms, max-height 400ms;
        transition: filter 400ms, max-height 400ms, -webkit-filter 400ms;
      }
    }

    .contact_wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 1.5rem;

        li {
          list-style: none;
          display: grid;
          grid-template-columns: 40px 1fr;
          gap: 0.5rem;
          align-items: center;

          .icon {
            aspect-ratio: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: solid 1px #fff;
            padding: 0.6rem;
          }

          .link {
            display: block;
            text-decoration: none;
            color: #fff;
            font-weight: 400;
            -webkit-transition: color 400ms;
            transition: color 400ms;

            &:hover {
              color: @primary-color;
            }
          }
        }
      }

      .menu_icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .menu_click {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-left: 1.5rem;
          margin-bottom: 0;
          padding: 0rem;

          .img {
            width: 48px;
            aspect-ratio: 1;
            -webkit-filter: invert(1);
            filter: invert(1);
            pointer-events: none;
            -webkit-transition: -webkit-filter 400ms;
            transition: -webkit-filter 400ms;
            transition: filter 400ms;
            transition: filter 400ms, -webkit-filter 400ms;
          }
        }
      }

      .login_icon {
        margin-left: 20px;
        padding-left: 20px;
        border-left: 1px solid #fff;

        .login_click {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover,
          &:focus,
          &.active {
            color: @primary-color;
          }

          svg {
            width: 36px;
            height: 36px;
            filter: invert(1);
            cursor: pointer;
            transition: filter 400ms;
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .header_wraper {
      padding: 1rem 0rem;

      .logo_wrapper img,
      .logo_wrapper svg {
        max-height: 70px;
      }

      .contact_wrap {
        ul li {
          grid-template-columns: 32px 1fr;

          .icon {
            padding: 0.5rem;
          }

          .link {
            font-size: 0.875rem;
          }
        }

        .menu_icon .menu_click {
          margin-left: 1.25rem;
          padding: 0;

          .img {
            width: 34px;
          }
        }

        .login_icon {
          .login_click {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

    &.fixed {
      .header_wraper {
        padding-block: 0.5rem;

        .logo_wrapper a img,
        .logo_wrapper a svg {
          max-height: 60px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .header_wraper .logo_wrapper img,
    .header_wraper .logo_wrapper svg {
      max-height: 60px;
    }

    &.fixed {
      .header_wraper {
        padding-block: 0.35rem;

        .logo_wrapper a img,
        .logo_wrapper a svg {
          max-height: 50px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .header_wraper .contact_wrap ul {
      display: none;
    }

    &.fixed {
      .header_wraper {
        padding-block: 0.35rem;

        .logo_wrapper a img,
        .logo_wrapper a svg {
          max-height: 45px;
        }
      }
    }
  }
}

.menu_wrapper {
  position: fixed;
  top: 0%;
  left: 0;
  z-index: 9999;
  width: 100dvw;
  height: 100dvh;
  background-color: black;
  transform: scale(1.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;

  &.open_menu {
    opacity: 1;
    visibility: visible;
    transform: none;
  }

  .menu_grid {
    display: grid;
    grid-template-columns: minmax(22%, 24%) 1fr;
    height: 100%;
    overflow-y: auto;

    .content_col {
      // background-image: url(../images/menu-bg-01.png);
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto;
      background-color: white;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: black;

      .content_col__box1 {
        padding: 8%;
        padding-top: 10%;
        position: static;

        .header_wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-bottom: 2rem;

          .logo {
            width: calc(100% - 50px);

            a {
              display: inline-block;
              text-decoration: none;

              img {
                display: block;
                max-height: 100px;
              }
            }
          }
        }

        .content {
          line-height: 1.6;
          font-weight: 500;
        }
      }

      .content_col__box2 {
        padding: 5% 8%;
        position: relative;
        border-bottom: solid 15px #e3000f;

        p {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-size: 1rem;
          color: @blackColor;
          font-weight: 500;
          gap: 0.5em;
          margin-bottom: 0.5em;

          .img {
            width: 1em;
            aspect-ratio: 1;
            -webkit-filter: brightness(0) saturate(100%) invert(28%) sepia(87%)
              saturate(7332%) hue-rotate(348deg) brightness(83%) contrast(119%);
            filter: brightness(0) saturate(100%) invert(28%) sepia(87%)
              saturate(7332%) hue-rotate(348deg) brightness(83%) contrast(119%);
          }
        }

        a {
          text-decoration: none;
          color: @blackColor;
          font-size: 2.5625rem;
          display: inline-block;
          transition: color 400ms;

          &:hover {
            color: @primary-color;
          }
        }
      }
    }

    .nav_col {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: 1fr 15%;

      .bg_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.1;
        z-index: -1;
        pointer-events: none;
      }

      .nav_wrap {
        border-right: solid 1px rgba(255, 255, 255, 0.2);
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);

        .nav {
          width: 100%;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 1rem 8%;

          > ul {
            width: 100%;
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            // display: grid;
            // grid-template-rows: repeat(3, auto);
            // grid-auto-flow: column;
            padding: 0 1rem;
            background-color: transparent;
            border: 0;

            &:before {
              display: none;
            }

            li {
              margin: 0.5em 0;
              overflow: visible;
              width: 50%;
              height: auto;
              line-height: normal;

              &:after {
                display: none;
              }

              .ant-menu-submenu-arrow {
                display: none;
              }

              .ant-menu-title-content {
                text-decoration: none;
                padding: 0.5em 0.5em;
                display: inline-block;
                color: @textWhite;
                font-size: 1.75rem;
                font-weight: 300;
                padding-left: 0.75em;
                position: relative;
                -webkit-transition: color 400ms;
                transition: color 400ms;

                &:before {
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: 0;
                  height: 0.9em;
                  width: 2px;
                  -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
                  background-color: currentColor;
                  -webkit-transition: background-color 400ms;
                  transition: background-color 400ms;
                }
              }

              &.ant-menu-item-active {
                .ant-menu-title-content {
                  padding-left: 1.35em;
                  color: @primary-color;

                  a {
                    color: @primary-color;
                  }

                  &::before {
                    width: 0.9em;
                    height: 2px;
                    background-color: @primary-color;
                    border-left-color: @primary-color;
                  }
                }

                .ant-menu.ant-menu-sub {
                  li {
                    .ant-menu-title-content {
                      color: @textWhite;

                      a {
                        color: @textWhite;
                      }
                    }

                    &.ant-menu-item-active {
                      .ant-menu-title-content {
                        color: @primary-color;

                        a {
                          color: @primary-color;
                        }
                      }
                    }
                  }
                }
              }

              &:hover,
              &:focus-within {
                .ant-menu-title-content {
                  color: @primary-color;

                  a {
                    color: @primary-color;
                  }

                  &::before {
                    background-color: currentColor;
                  }
                }

                .ant-menu.ant-menu-sub {
                  li {
                    .ant-menu-title-content {
                      color: @textWhite;

                      a {
                        color: @textWhite;
                      }
                    }

                    &:hover,
                    &:focus-within {
                      .ant-menu-title-content {
                        color: @primary-color;

                        a {
                          color: @primary-color;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-menu.ant-menu-sub {
            background-color: transparent;

            li {
              width: 100%;

              .ant-menu-title-content {
                padding: 0.2rem;
                padding-left: 1rem;
                font-size: 1.1rem;

                &:before {
                  width: 0;
                  height: 0;
                  background-color: transparent;
                  border-left: 5px solid currentColor;
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                }

                &:after {
                  display: none;
                }

                // &:hover {
                // 	&:before {
                // 		border-left: 5px solid @primary-color;
                // 	}
                // }
              }

              &.ant-menu-item-active {
                .ant-menu-title-content {
                  color: @primary-color;

                  a {
                    color: @primary-color;
                  }
                }
              }
            }
          }
        }
      }

      .info_wrap {
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;

        .box {
          padding: 5%;

          .img {
            max-width: 600px;
            width: 100%;
            display: block;
            margin: auto;
            margin-bottom: 2rem;
            opacity: 0.3;
          }

          h4 {
            font-size: 2.25rem;
            font-weight: 400;
            color: white;
            margin-bottom: 0.5em;
          }

          p {
            line-height: 1.6;
            font-weight: 300;
          }
        }
      }

      .contect_wrap {
        border-right: solid 1px rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;

        .box {
          padding: 1rem;
          padding-left: 8%;

          p {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 0.75rem;
            font-size: 1.125rem;

            &:last-child {
              margin-bottom: 0;
            }

            label {
              font-weight: 300;
              margin-right: 0.5rem;
              display: flex;
              align-items: center;

              .img {
                width: 1em;
                height: 1em;
                margin: 0;
                margin-right: 0.75rem;
              }
            }

            a {
              font-weight: 300;
              text-decoration: none;
              color: white;
              -webkit-transition: color 400ms;
              transition: color 400ms;

              &:hover {
                color: @primary-color;
              }
            }
          }
        }
      }

      .follow_wrap {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          align-items: stretch;
          justify-content: center;
          flex-wrap: wrap;
          height: 100%;

          li {
            border-right: solid 1px rgba(255, 255, 255, 0.2);
            width: auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 0;
            &:first-child {
              width: 100%;
              flex-basis: 100%;
              border-bottom: solid 1px rgba(255, 255, 255, 0.2);
              border-right: none;
            }

            p {
              font-size: 1.5rem;
              font-weight: 300;
              margin: 0;
            }

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              width: 50px;
              height: 50px;
              border-radius: 50px;
              border: solid 1px #fff;
              padding: 0.65rem;
              background-color: rgba(255, 255, 255, 0.05);
              cursor: pointer;
              transition: all 0.5s ease;

              svg {
                filter: brightness(0) invert(1);
                width: 20px;
                height: 20px;
                transition: all 0.5s ease;
              }

              &:hover {
                background-color: #fff;
                border-color: #fff;

                svg {
                  filter: none;
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
    }
  }

  .close_menu {
    width: 50px;
    position: absolute;
    top: 4%;
    right: 4%;
    cursor: pointer;
    display: block;
    z-index: 999;
    -webkit-transition: -webkit-filter 400ms;
    transition: -webkit-filter 400ms;
    transition: filter 400ms;
    transition: filter 400ms, -webkit-filter 400ms;

    .img {
      width: 50px;
      aspect-ratio: 1;
    }
  }

  @media (max-width: 1560px) {
    .menu_grid {
      grid-template-columns: minmax(22%, 23%) 1fr;

      .content_col {
        .content_col__box1 {
          padding: 6%;

          .header_wrap {
            margin-bottom: 1rem;

            .logo a img {
              max-height: 60px;
            }
          }

          .content {
            font-size: 13px;
          }
        }

        .content_col__box2 {
          border-width: 10px;
          padding: 1rem 6%;

          p {
            font-size: 0.875rem;
          }

          a {
            font-size: 1.75rem;
          }
        }
      }

      .nav_col {
        .nav_wrap .nav {
          padding: 1rem 5%;
        }

        .info_wrap {
          .box {
            .img {
              max-width: 500px;
              margin-bottom: 1rem;
            }

            h3,
            h4 {
              font-size: 1.75rem;
            }
          }
        }

        .contect_wrap .box {
          padding-left: 5%;

          p {
            font-size: 0.875rem;
          }
        }

        .follow_wrap ul li {
          p {
            font-size: 1.25rem;
          }

          .icon {
            width: 40px;
            height: 40px;
            padding: 0.5rem;
          }
        }
      }
    }

    .close_menu {
      width: 40px;
    }
  }

  @media (max-width: 1366px) {
    .menu_grid .nav_col {
      .nav_wrap .nav ul li .ant-menu-title-content {
        font-size: 1.5rem;
      }

      .info_wrap .box .img {
        max-width: 400px;
      }
    }
  }

  @media (max-width: 1199px) {
    .menu_grid {
      .content_col {
        .content_col__box1 .content {
          font-size: 12px;
        }

        .content_col__box2 {
          p {
            font-size: 12px;
          }

          a {
            font-size: 1.5rem;
          }
        }
      }

      .nav_col {
        .nav_wrap .nav {
          ul li {
            margin: 0.25em 0;

            .ant-menu-title-content {
              font-size: 1.25rem;
            }
          }

          .ant-menu.ant-menu-sub li .ant-menu-title-content {
            font-size: 0.9rem;
          }
        }

        .info_wrap .box {
          .img {
            max-width: 300px;
          }

          h3,
          h4 {
            font-size: 1.5rem;
          }

          p {
            font-size: 12px;
          }
        }

        .contect_wrap .box p {
          font-size: 12px;
        }

        .follow_wrap ul li {
          padding: 8px 0;
          p {
            font-size: 1rem;
          }

          .icon {
            width: 34px;
            height: 34px;
            padding: 0.5rem;
          }
        }
      }
    }

    .close_menu {
      right: 2%;
    }
  }

  @media (max-width: 991px) {
    .menu_grid {
      grid-template-columns: 40% 1fr;

      .nav_col {
        grid-template-columns: 1fr;
        grid-template-rows: unset;

        .contect_wrap {
          border-right: none;
          border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .menu_grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;

      .content_col {
        background-size: contain;
        background-repeat: repeat;

        .content_col__box1 {
          padding: 1rem;

          .header_wrap {
            margin-bottom: 0.75rem;
          }

          .content {
            font-size: 12px;
          }
        }

        .content_col__box2 {
          border-width: 10px;
          padding: 1rem;
          padding-top: 0.5rem;

          p {
            font-size: 14px;
          }

          a {
            font-size: 1.75rem;
          }
        }
      }

      .nav_col {
        grid-template-rows: 1fr 100px;

        .nav_wrap .nav {
          padding: 1rem;

          ul li .ant-menu-title-content {
            font-size: 1.125rem;
          }
        }

        .info_wrap {
          display: none;
        }

        .contect_wrap {
          display: none;
        }

        .follow_wrap ul li {
          &:first-child {
            display: none;
          }
        }
      }
    }

    .close_menu {
      // position: static;
      filter: invert(1);
      width: 44px;
      top: 2%;
    }
  }
}
body:has(.menu_wrapper.open_menu) {
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
}
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  height: auto;
  line-height: normal;
  margin: 0;
}
