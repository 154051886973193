.privacyPolicy {
  h5,
  p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 1em;
    text-align: justify;
  }
  h5 {
    margin-top: 1.6em;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.3;
    span {
      color: #fff !important;
    }
  }

  .list_cls {
    margin: 0 0 20px 30px;
    li {
      list-style: disc outside;
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgb(0 0 0 / 80%);
  }
  .ant-modal {
    .ant-modal-content {
      background-color: #fff;
      border-radius: 8px;
      border-bottom: 5px solid @secondary-color;
      box-shadow: 0px 0px 47px -5px rgb(0 0 0 / 75%);
      .input_group_blk .input_group {
        margin-bottom: 2rem;
        .ant-input {
          background-color: transparent;
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 0 20px;
          height: 50px;
          font-size: 1rem;
          color: #000;
        }
      }
      .ant-modal-close {
        top: -15px;
        right: -15px;
        .ant-modal-close-x {
          background-color: #fff;
          color: @primary-color;
          border: 2px solid @secondary-color;
          font-size: 11px;
          border-radius: 50%;
          //   box-shadow: 0px 0px 47px -5px rgb(0 0 0 / 75%);
          width: 40px;
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-modal-close-icon {
            border: 2px solid @primary-color;
            border-radius: 50%;
            padding: 3px;
          }
        }
      }
    }
    .ant-modal-header {
      background: #fff;
      border: 0;
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 0;
      padding-top: 24px;
      form {
        max-width: 80%;
        margin: 0 auto;
      }
    }
    .ant-modal-footer {
      border: 0;
    }
    .ant-modal-title {
      color: @primary-color;
      text-align: center;
      font-size: 22px;
    }
    .modalHeaderLogo {
      text-align: center;
      padding: 30px 0;
      svg {
        max-width: 200px;
        height: auto;
      }
    }
    .modalBG {
      height: 110px;
      margin-top: 10px;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
