.sitemap_section {
  .sitemap_wrap {
    max-width: 1600px;
    margin: auto;
    margin-top: 3em;
    padding: 0 1rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        position: relative;
        width: 14.2857142857%;
        text-align: center;

        &::before {
          content: "";
          position: absolute;
          top: -2rem;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: @textWhite;
        }

        &::after {
          content: "";
          position: absolute;
          top: -2rem;
          left: 50%;
          z-index: 0;
          transform: translateX(-50%);
          width: 1px;
          height: 50px;
          background-color: transparent;
          border-left: 1px solid @textWhite;
        }

        &.active {
          width: 100%;
          margin-bottom: 4rem;
          padding: 0;

          .link {
            background-color: @textWhite;
            color: @primary-color;
            padding: 0.75rem 1.5rem;
            font-size: 1.125em;
            font-weight: 600;
            border: none;
            transition: all 0.5s ease-in-out;
          }

          &::before {
            display: none;
          }

          &::after {
            top: calc(100% - 6px);
            height: 38px;
          }
        }

        &:nth-child(2)::before {
          left: 50%;
          right: 0%;
          width: 50%;
          height: 10px;
          border-top: 1px solid @textWhite;
          border-top-left-radius: 10px;
          border-left: 1px solid @textWhite;
          background-color: transparent;
        }

        &:nth-child(2)::after {
          left: 50%;
          height: 25px;
          top: -22px;
        }
        &:last-child::before {
          right: 50%;
          left: 0%;
          width: 50%;
          height: 10px;
          border-top: 1px solid @textWhite;
          border-top-right-radius: 10px;
          border-right: 1px solid @textWhite;
          background-color: transparent;
        }

        &:last-child::after {
          left: 50%;
          height: 25px;
          top: -22px;
        }
        // &:last-child::before {
        //   width: 50%;
        // }

        &.center {
          &::before {
            top: -41px;
            right: 50%;
            left: 0%;
            width: 50%;
            height: 10px;
            border-bottom: 1px solid @textWhite;
            border-bottom-right-radius: 10px;
            border-right: 1px solid @textWhite;
            background-color: transparent;
          }
          .center {
            position: absolute;
            top: -41px;
            right: 0%;
            left: 50%;
            width: 50%;
            height: 10px;
            border-bottom: 1px solid @textWhite;
            border-bottom-left-radius: 10px;
            border-left: 1px solid @textWhite;
            background-color: transparent;
          }
        }

        .link {
          z-index: 3;
          position: relative;
          display: inline-block;
          font-size: 1em;
          font-weight: 400;
          color: @textWhite;
          text-transform: unset;
          padding: 0.75em 1.5em;
          margin: 0.25em 0.5em;
          background-color: @PrimaryBlackColor;
          border: solid 1px @secondary-color;
          border-radius: 8px;
          transition: all 0.5s ease-in-out;

          &:hover,
          &:focus-within {
            background-color: @secondary-dark-color;
          }
        }

        .sub_menu_wrap {
          flex-direction: column;
          max-width: 300px;
          width: 100%;

          li {
            width: 100%;

            &::before {
              display: none;
            }

            .link {
              margin: 1em 0;
              background-color: @secondary-dark-color;
              border: 1px solid @secondary-color;
              color: @textWhite;

              &:hover,
              &:focus-within {
                background-color: @PrimaryBlackColor;
              }
            }
          }
        }
      }
    }

    // ul {
    //   list-style: none;
    //   padding: 0;
    //   margin: 0;
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;

    //   .item {
    //     position: relative;
    //     width: 14.2857142857%;
    //     text-align: center;

    //     &::before {
    //       content: "";
    //       position: absolute;
    //       top: -2rem;
    //       left: 0;
    //       width: 100%;
    //       height: 1px;
    //       background-color: @textWhite;
    //     }

    //     &::after {
    //       content: "";
    //       position: absolute;
    //       top: -2rem;
    //       left: 50%;
    //       transform: translateX(-50%);
    //       width: 1px;
    //       height: 50px;
    //       background-color: @textWhite;
    //       z-index: 0;
    //     }

    //     &.active {
    //       width: 100%;
    //       margin-bottom: 4rem;
    //       padding: 0;

    //       .link {
    //         background-color: @secondary-color;
    //         color: @textWhite;
    //         padding: 0.75rem 1.5rem;
    //         font-size: 1.125em;
    //         border: none;
    //         transition: all 0.5s ease-in-out;
    //       }

    //       &::before {
    //         display: none;
    //       }

    //       &::after {
    //         top: 2rem;
    //         height: 56px;
    //       }
    //     }

    //     &:nth-child(2)::before {
    //       left: 50%;
    //       right: 0%;
    //       width: 50%;
    //     }

    //     &:nth-child(2)::after {
    //       left: 50%;
    //     }

    //     &:last-child::before {
    //       width: 50%;
    //     }

    //     .link {
    //       z-index: 3;
    //       position: relative;
    //       display: inline-block;
    //       font-size: 1em;
    //       font-weight: 400;
    //       color: rgba(255, 255, 255, 0.6);
    //       text-transform: unset;
    //       padding: 0.75em 1.5em;
    //       margin: 0.25em 0.5em;
    //       background-color: @layout-sidebar-background;
    //       border: solid 1px #3e3e3e;
    //       transition: all 0.5s ease-in-out;

    //       &:hover,
    //       &:focus-within {
    //         background-color: @primary-color;
    //         color: @textWhite;
    //         transform: translateY(-8%);
    //         box-shadow: 5px 5px 5px rgba(55, 55, 55, 0.55);
    //         filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3, MakeShadow=true, ShadowOpacity=0.30);
    //         zoom: 1;
    //       }
    //     }

    //     .sub_menu_wrap {
    //       flex-direction: column;
    //       max-width: 300px;
    //       width: 100%;

    //       li {
    //         width: 100%;

    //         &::before {
    //           display: none;
    //         }

    //         .link {
    //           margin: 1em 0;
    //           background-color: @PrimaryBlackColor;
    //           box-shadow: 0 0 0 @SecondaryBlackColor;

    //           &:hover,
    //           &:focus-within {
    //             background-color: @secondary-color;
    //             color: @textWhite;
    //             transform: translateY(-8%);
    //             box-shadow: 5px 5px 5px rgba(55, 55, 55, 0.55);
    //             filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=3, MakeShadow=true, ShadowOpacity=0.30);
    //             zoom: 1;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }

  @media (max-width: 991px) {
    .sitemap_wrap {
      margin-top: 2em;

      ul {
        flex-direction: column;
        max-width: unset;
        margin: auto;

        .item {
          text-align: center;
          width: 100%;

          &.center .center,
          &:before {
            display: none;
          }

          &::after,
          &:nth-child(2):after,
          &:last-child::after {
            top: -1rem;
            left: 50%;
            bottom: auto;
            -webkit-transform: none;
            transform: none;
          }

          .link {
            padding: 0.65em 1.5em;
            margin: 0.75em 0;
          }

          &.active {
            margin-bottom: 0rem;

            .link {
              padding: 0.75rem 1rem;
            }
          }

          .sub_menu_wrap {
            margin: 0 auto;
            max-width: unset;

            li {
              .link {
                font-size: 13px;
                padding: 0.5em 1em;
                margin: 0.5rem 0;
              }

              &::after {
                left: 50%;
              }
            }
          }
        }
      }
    }
  }
}
