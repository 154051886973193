@import (css)
  url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

// Theme Variables
@import "~antd/lib/style/themes/default.less";
@import "variables.less";

// Third Party Stylesheets
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry

// Common and Overrides Stylesheets
@import "./utils/utilities.less";
@import "./common.less";
@import "./override.less";

// Layout Stylesheets
@import "./layout/header.less";
@import "./layout/footer.less";
@import "./layout/app-header.less";
@import "./layout/app-sidebar.less";

// Utils Stylesheets
@import "./utils/container.less";

// Views Stylesheets
@import "./views/auth.less";

// Section Stylesheets
@import "./sections/pageBanner.less";
@import "./sections/modal.less";
@import "./sections/dropDown.less";
@import "./table.less";

// Pages Stylesheets
@import "./pages/joinUsPage.less";
@import "./pages/homepage.less";
@import "./pages/notFoundPage.less";
@import "./pages/networkPage.less";
@import "./pages/sitemapPage.less";
@import "./pages/commonPage.less";
@import "./pages/eBookPage.less";
@import "./pages/contactUsPage.less";
@import "./pages/travelFreePage.less";
@import "./pages/newsletter.less";
