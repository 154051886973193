footer.ant-layout-footer {
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
  font-size: 1rem;
  padding: 0;

  .footer_top {
    background-color: #fff;
    border-radius: 100px;
    padding: 1.5rem 2rem;
    align-items: center;

    .col_info {
      display: flex;
      align-items: center;
      border-right: solid 1px #ddd;

      h3 {
        font-size: 2rem;
        color: @secondary-color;
        margin: 0;
        margin-right: 1rem;
        font-weight: normal;
      }

      .links {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        img {
          display: block;
          max-width: 160px;
        }
      }
    }

    .col_form {
      padding-left: 4rem !important;

      h4 {
        font-size: 1.5rem;
        font-weight: 500;
        color: #000;
        padding-left: 0.5em;
        margin-bottom: 0.5rem;
      }

      .input_group {
        position: relative;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        border: solid 1px #dbdbdb;
        margin: 0;

        .ant-form-item {
          margin: 0;

          .ant-form-item-control-input {
            min-height: 50px;
          }
        }

        .ant-input {
          height: 100%;
          border: none;
          width: calc(100% - 140px);
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 0.5rem 1rem;
          font-weight: 500;
          font-size: 1.125rem;
          color: #222;
        }

        .btn {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 140px;
          min-width: 1px;
          min-height: 1px;
          border-radius: 50px;
          font-size: 1rem;
          background-color: @primary-color;
          color: #fff;
          border: 0;
          box-shadow: none;
          cursor: pointer;

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .footer_bottom {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.6;
    padding-top: 3rem;
    padding-bottom: 4rem;

    > div {
      width: 20%;
      margin-bottom: 1rem;
    }

    address {
      color: white;
      font-size: 1rem;
    }

    a {
      color: white;
      text-decoration: none;
      transition: color 400ms;
      &.active,
      &:hover,
      &:focus {
        color: @primary-color;
      }
    }

    p {
      margin-bottom: 0;
    }

    > * {
      padding: 0 0.75rem;
    }

    .col_usefulLink {
      .links {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        // grid-template-columns: repeat(2, auto);
        grid-template-columns: repeat(2, auto);
        grid-auto-flow: row;

        li {
          list-style: none;
          padding-right: 10px;

          a {
            text-decoration: none;
            font-size: 1rem;
            display: inline-block;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .col_socialLink {
      .social_link {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: 90%;

        li {
          list-style: none;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            width: 42px;
            height: 42px;
            border-radius: 42px;
            border: solid 1px #fff;
            padding: 0.65rem;
            background-color: rgba(255, 255, 255, 0.05);
            cursor: pointer;
            transition: all 0.5s ease;

            svg,
            .img {
              filter: brightness(0) invert(1);
              width: 20px;
              height: 20px;
              transition: all 0.5s ease;
            }

            &:hover {
              background-color: #fff;

              svg {
                filter: none;
                transform: scale(1.2);
              }
            }
          }
        }
      }
    }

    .col_qrCode {
      &__img {
        img {
          max-width: 120px;
          display: block;
        }
      }
    }

    .col_contact {
      &__wrap {
        font-size: 0.875rem;
        font-weight: 400;

        h4 {
          font-size: 1.375rem;
          font-weight: 400;
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .title_wrap > * {
    font-size: 1.375rem;
    font-weight: 400;
    color: #fff;
    position: relative;
    padding-bottom: 0.65rem;
    margin-bottom: 1.5rem;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100px;
      height: 2px;
      background-color: @primary-color;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0%;
      width: 15px;
      height: 2px;
      background-color: #fff;
      -webkit-animation: moveLine2 8s linear infinite;
      animation: moveLine2 8s linear infinite;
    }
  }

  .footer_cpy {
    border-top: solid 1px rgba(255, 255, 255, 0.1);
    padding-top: 3rem;
    padding-bottom: 4rem;
    position: relative;

    .backToTop {
      position: absolute;
      top: calc(48px / -2);
      right: 2%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 6px;
      padding: 0;
      background-color: @primary-color;
      color: #fff;

      .img {
        width: 35px;
        transform: scaleX(-1) rotate(-90deg);
        filter: invert(1);
      }
    }

    .container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      a {
        color: white;
        text-decoration: none;
        transition: color 400ms;

        &.active,
        &:hover,
        &:focus {
          color: @primary-color;
        }
      }

      .col_link {
        margin-left: 0;
        margin-right: auto;

        a {
          margin-right: 1rem;
        }
      }

      .col_office {
        margin: auto;
        text-align: center;

        .title_wrap {
          > *::before,
          > *::after {
            left: 50%;
            transform: translateX(-50%);
          }

          > *::after {
            animation: moveLine3 8s linear infinite;
          }
        }
      }

      .col_cpy {
        margin-right: 0;
        margin-left: auto;
        text-align: right;
        padding: 0 20px;
        text-align: center;
      }
    }
  }

  .road_wrap {
    height: 60px;
    width: 100%;
    position: relative;
    background-position: 0px 0px;
    background-repeat: repeat;
    background-size: 100% 115px;

    .truck1 {
      background-position: 0 -42px;
      background-repeat: no-repeat;
      background-size: auto;
      display: block;
      width: 55px;
      height: 38px;
      position: absolute;
      top: -16px;
      left: -60px;
      animation: moveTruck1 28s 1s infinite linear;

      @keyframes moveTruck1 {
        0% {
          left: -60px;
        }

        100% {
          left: calc(100% + 60px);
        }
      }
    }

    .truck2 {
      width: 61px;
      height: 34px;
      background-position: -55px -42px;
      background-repeat: no-repeat;
      background-size: auto;
      display: block;
      position: absolute;
      bottom: 8px;
      right: -60px;
      animation: moveTruck2 28s 1s infinite linear;

      @keyframes moveTruck2 {
        0% {
          right: -60px;
        }

        100% {
          right: calc(100% + 60px);
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .footer_top {
      padding: 1rem 1.5rem;

      .col_info {
        h3 {
          font-size: 1.5rem;
        }

        .links img {
          max-width: 120px;
        }
      }

      .col_form {
        padding-left: 3rem !important;

        h4 {
          font-size: 1.125rem;
        }

        .input_group {
          height: 42px;

          .ant-form-item .ant-form-item-control-input {
            min-height: 42px;
          }

          .ant-input {
            width: calc(100% - 120px);
            font-size: 0.875rem;
            padding: 0.5rem 1rem;
          }

          .btn {
            width: 120px;
          }
        }
      }
    }

    .footer_bottom {
      padding-top: 3rem;
      padding-bottom: 3rem;

      address {
        font-size: 0.875rem;
      }

      .col_usefulLink .links li a {
        font-size: 0.875rem;
      }

      .col_socialLink .social_link {
        gap: 0.5rem;

        li a {
          width: 35px;
          height: 35px;
          padding: 0.45rem;
        }
      }

      .col_qrCode__img img {
        max-width: 100px;
      }

      .col_contact__wrap {
        font-size: 0.75rem;

        h4 {
          font-size: 1rem;
          line-height: 19px;
        }
      }
    }

    .title_wrap > * {
      font-size: 1.125rem;
      margin-bottom: 1.25rem;
    }

    .footer_cpy {
      padding-top: 2rem;
      padding-bottom: 4rem;

      .backToTop {
        width: 40px;
        height: 40px;
        top: -20px;
        right: 1rem;

        .img {
          width: 30px;
        }
      }

      .container a {
        font-size: 13px;
      }

      address {
        font-size: 13px;
      }

      .container .col_cpy {
        font-size: 13px;
      }
    }

    .road_wrap {
      height: 50px;
      background-size: 100% 95px;

      .truck1 {
        transform: scale(0.85);
        top: -20px;
      }

      .truck2 {
        transform: scale(0.85);
        bottom: 3px;
      }
    }
  }

  @media (max-width: 1199px) {
    .footer_top {
      margin: 0 !important;
      padding: 0.75rem 1rem;

      .col_info {
        h3 {
          font-size: 1.25rem;
        }

        .links img {
          max-width: 90px;
        }
      }

      .col_form {
        padding-left: 2rem !important;

        h4 {
          font-size: 1rem;
        }

        .input_group {
          height: 40px;

          .ant-input {
            width: calc(100% - 100px);
            font-size: 0.875rem;
            padding: 0.5rem 1rem;
          }

          .btn {
            width: 100px;
          }
        }
      }
    }

    .footer_bottom {
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      > div {
        width: 25%;
      }

      .col_contact {
        width: 100%;

        .col_contact__wrap {
          display: flex;
          gap: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .footer_top {
      padding: 1rem;
      border-radius: 15px;

      .col_info {
        margin-bottom: 1.25rem;
        border: none;
      }

      .col_form {
        padding-left: 15px !important;

        h4 {
          padding-left: 0;
        }
      }
    }

    .footer_bottom {
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      > div {
        width: 50%;
      }
    }

    .footer_cpy .container {
      grid-template-columns: 1fr;
      justify-content: center;

      > * {
        grid-row: unset;
      }

      .col_link {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        text-align: center;
        order: 2;

        .title_wrap {
          display: none;
        }
      }

      .col_cpy {
        order: 3;
        width: 100%;
        text-align: center;

        .title_wrap {
          display: none;
        }
      }

      address {
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .footer_top {
      .col_info {
        margin-bottom: 1.25rem;
        border: none;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        text-align: center;
      }

      .col_form h4 {
        font-size: 0.875rem;
        text-align: center;
      }
    }

    .footer_bottom {
      padding-top: 2.5rem;
      padding-bottom: 1.5rem;

      > div {
        width: 100%;
        margin-bottom: 1.5rem;
      }

      .col_contact .col_contact__wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
      }
    }
  }
}
