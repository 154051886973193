.banner {
  position: relative;

  .item_wrap {
    height: 100vh;
    min-height: 800px;
    background-color: #222;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.7;
      z-index: 1;
    }

    .bg_view {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      z-index: 1;
      display: block;
    }

    .container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 2;
      padding-top: 80px;

      .banner_content {
        font-family: @secondary_font;

        h1,
        h2 {
          font-size: 6rem;
          font-weight: 100;
          text-transform: uppercase;
          margin-bottom: 0rem;
          line-height: normal;

          strong {
            font-weight: 300;
            font-size: 120%;
          }
        }

        h2 {
          font-size: 3rem;
          margin-bottom: 12px;
        }
        p {
          margin-bottom: 12px;
        }

        .link {
          text-decoration: none;
          color: white;
          font-weight: 300;
          text-transform: uppercase;
          font-size: 1.8125rem;
          display: inline-flex;
          align-items: center;
          transition: all 400ms ease;

          &:hover {
            color: @primary-color;

            .arrow {
              &:before {
                border-color: @primary-color;
              }
            }
          }

          .arrow {
            display: inline-block;
            // margin-left: 0.25rem;
            position: relative;

            .img {
              width: 30px;
              height: 24px;
              display: block;
              filter: invert(1) brightness(0.8);
              animation: arrowMove 3s infinite linear;
              transition: transform 400ms;

              @keyframes arrowMove {
                0% {
                  transform: translateX(0) scaleX(-1);
                }

                50% {
                  transform: translateX(15px) scaleX(-1);
                }

                100% {
                  transform: translateX(0px) scaleX(-1);
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              right: -15px;
              transform: translateY(-50%);
              width: 30px;
              height: 30px;
              border: solid 1px #fff;
              border-radius: 50px;
              transition: all 400ms ease;
            }
          }
        }
      }
    }
  }

  .banner_slick {
    position: relative;

    .slick-dots {
      position: absolute;
      width: auto;
      top: 50%;
      bottom: auto;
      right: 0;
      transform: translateY(-50%);
      display: flex !important;
      flex-direction: column;

      li {
        display: block;
        width: auto;
        height: auto;
        margin: 0;

        button {
          font-size: 1.5rem;
          line-height: normal;
          height: auto;
          width: auto;
          background-color: transparent;
          color: white;
          box-shadow: none;
          padding: 1.25rem 1.5rem;
          padding-top: 1rem;
          margin: 0;
          border-radius: 0;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: auto;
            bottom: 1rem;
            left: 0;
            right: 0;
            width: 45%;
            height: 1px;
            margin: auto;
            background-color: #fff;
            transition: width 400ms;
          }

          &::after {
            content: "";
            position: absolute;
            bottom: calc(1rem - 15px / 2);
            left: calc(15px / -1);
            width: 15px;
            height: 15px;
            border-radius: 50px;
            border: solid 1px #fff;
            transform: translateX(25px);
            opacity: 0;
            transition: transform 200ms, opacity 200ms;
          }
        }

        &.slick-active {
          button {
            &::before {
              width: 100%;
            }

            &::after {
              opacity: 1;
              transform: none;
              transition: transform 200ms 200ms, opacity 200ms 300ms;
            }
          }
        }
      }
    }
  }

  .slick-track {
    .slick-slide {
      .banner_content {
        > * {
          display: none;
        }
      }

      &.slick-current {
        .banner_content {
          > * {
            display: block;

            @keyframes fadeInForUpText {
              0% {
                opacity: 0;
                transform: translateY(100px);
              }

              50% {
                transform: none;
                opacity: 0.2;
              }

              100% {
                opacity: 1;
                transform: none;
              }
            }

            &:nth-child(1) {
              animation-name: fadeInForUpText;
              animation-duration: 1000ms;
            }

            &:nth-child(2) {
              animation-name: fadeInForUpText;
              animation-duration: 1400ms;
            }
          }
        }
      }
    }
  }

  .goToTacking {
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 90;
    transform: translateX(-50%) translateY(-1px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 0;
    min-width: unset;
    min-height: unset;
    height: auto;
    border: none;
    padding: 0 0 0 25px;
    font-family: @secondary_font;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    transition: all 0.5s ease-in-out;
    background-color: @secondary-dark-color;

    .icon {
      border-radius: 50px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: @secondary-color;
      padding: 5px 25px 5px 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transform: translateX(1px);
      transition: all 0.5s ease-in-out;

      svg {
        width: 40px;
        height: 40px;

        path {
          fill: @textWhite;
        }
      }
    }

    &:hover,
    &:focus-within {
      transform: translateX(-50%) translateY(-3px);
      background-color: @secondary-color;
      color: @textWhite;
      box-shadow: 0 0 15px rgba(255, 255, 255, 0.2);

      .icon {
        background-color: @secondary-dark-color;

        svg path {
          fill: #fff;
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .item_wrap {
      min-height: 600px;

      .container .banner_content {
        h1 {
          font-size: 5rem;
        }

        h2 {
          font-size: 2rem;
        }

        .link {
          font-size: 1.35rem;

          .arrow {
            transform: scale(0.8) translateX(-10px) translateY(5px);
          }
        }
      }
    }

    .banner_slick {
      .slick-dots li button {
        font-size: 1.25rem;
        padding: 1rem 1.25rem;
        padding-top: 0.75rem;

        &:before {
          bottom: 0.75rem;
        }

        &::after {
          bottom: calc(0.75rem - 6px);
          left: -12px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  @media (max-width: 1366px) {
    .item_wrap {
      .container .banner_content {
        h1 {
          font-size: 4.5rem;
        }

        // h2 {
        //   font-size: 1.5rem;
        // }
      }
    }
  }

  @media (max-width: 1199px) {
    .item_wrap {
      .container .banner_content {
        h1 {
          font-size: 3.5rem;
        }

        h2 {
          font-size: 1.8rem;
        }

        .link {
          font-size: 1.25rem;

          .arrow {
            transform: scale(0.65) translateX(-10px) translateY(5px);
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .item_wrap {
      min-height: unset;
      height: 600px;

      .container .banner_content {
        h1 {
          font-size: 2.5rem;
        }

        // h2 {
        //   font-size: 0.825rem;
        // }
        .link {
          font-size: 1.125rem;
        }
      }
    }

    .goToTacking {
      position: absolute;
    }
  }

  @media (max-width: 767px) {
    .item_wrap {
      height: 500px;

      .container {
        padding-top: 30px;

        .banner_content {
          h1 {
            font-size: 2rem;
            font-weight: 500;
            line-height: 1;
            strong {
              margin: 10px auto;
              display: block;
            }
          }
          h2,
          p {
            font-size: 1.3rem;
            font-weight: 300;
            line-height: 1.4;
            margin: 15px auto 10px;
          }
        }
      }
    }

    .banner_slick {
      .slick-dots {
        padding: 0;
        bottom: 0;
        top: unset;
        transform: none;
        flex-direction: row;
        width: 100%;
        justify-content: center;

        li.slick-active button::after {
          opacity: 0;
        }
      }
    }

    .goToTacking {
      gap: 10px;
      padding: 0 0 0 25px;
      font-size: 18px;
      transform: translateX(-50%) translateY(-8px);

      .icon {
        padding: 10px 20px 10px 10px;

        svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  @media (min-width: 767px) and (max-width: 991px) {
    .item_wrap .container {
      max-width: 88%;
    }
  }
}

.aboutUs_section {
  .ant-row {
    .col_list {
      padding: 0 1rem;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        border: solid 1px rgba(255, 255, 255, 0.08);

        li {
          border: solid 1px rgba(255, 255, 255, 0.08);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          padding: 1.5rem;

          &:first-child {
            grid-row: 1/3;

            .content {
              font-size: 1.5rem;

              strong {
                font-size: 4rem;
              }
            }
          }

          .icon {
            margin-bottom: 0.5rem;

            img {
              max-width: 120px;
              max-height: 120px;
              display: block;
            }
          }

          .content {
            font-size: 1.125rem;

            strong {
              font-size: 2.125rem;
              font-weight: 600;
            }
          }
        }
      }
    }

    .col_content {
      padding: 0 1rem;
      text-align: justify;
    }
  }

  @media (max-width: 1560px) {
    .ant-row .col_list ul li {
      padding: 1.25rem;

      .icon img {
        max-width: 50px;
        max-height: 50px;
      }

      .content {
        font-size: 1rem;

        strong {
          font-size: 2rem;
        }
      }

      &:first-child {
        .content {
          font-size: 1.25rem;

          strong {
            font-size: 3rem;
          }
        }

        .icon img {
          max-width: 100px;
          max-height: 100px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .ant-row .col_list ul li {
      padding: 1rem;

      .icon img {
        max-width: 40px;
        max-height: 40px;
      }

      .content {
        font-size: 0.875rem;

        strong {
          font-size: 1.5rem;
        }
      }

      &:first-child {
        .content {
          font-size: 1.125rem;

          strong {
            font-size: 2.5rem;
          }
        }

        .icon img {
          max-width: 80px;
          max-height: 80px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .ant-row .col_list {
      order: 2;

      ul {
        max-width: 500px;
        margin: auto !important;
      }
    }
  }
}

.arrowBtnSide {
  .slick-arrow {
    border-radius: 50px;
    padding: 5px;
    width: 45px;
    height: 45px;
    aspect-ratio: 1;
    background-color: rgba(0, 0, 0, 0);
    border: solid 2px #a59a9a;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
    transition: border-color 400ms;
    cursor: pointer;

    &:before {
      display: none;
    }

    &.slick-prev {
      left: -50px;
    }

    &.slick-next {
      right: -50px;
    }

    &:hover {
      border-color: @secondary-color;

      .img {
        filter: invert(15%) sepia(44%) saturate(4609%) hue-rotate(233deg)
          brightness(90%) contrast(90%);
      }
    }

    .img {
      filter: invert(1) opacity(0.75);
      max-width: 40px;
      width: 100%;
      display: block;
      transition: filter 400ms;
    }

    &.slick-next .img {
      transform: scaleX(-1);
    }
  }

  @media (max-width: 1560px) {
    .slick-arrow {
      width: 40px;
      height: 40px;
    }
  }

  @media (max-width: 991px) {
    .slick-slider {
      padding-top: 50px;
    }

    .slick-arrow {
      top: 0;
      transform: none;

      &.slick-prev {
        left: calc(50% - 50px);
      }

      &.slick-next {
        right: calc(50% - 50px);
      }
    }
  }
}

.ourServices_section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .ourServices_wrap {
    .slider_wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .btn_wrap {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
      }

      .slick_wrap {
        // width: 85%;
        width: calc(100% - 90px);

        .slick-track {
          display: flex;

          .slick-slide {
            height: auto;
            padding: 1rem;
            position: relative;
            padding-bottom: 30px;

            & > div {
              height: 100%;
              //   background-color: #fff;
              border-radius: 0;
              overflow: visible;
              display: flex !important;
              align-items: center;
              justify-content: center;

              .item_wrap {
                font-size: 1rem;
                font-weight: 400;
                color: @textWhite;
                text-align: center;
                position: relative;
                z-index: 2;
                transition: color 400ms;
                cursor: pointer;
                padding: 1rem;
                min-height: 180px;
                display: flex !important;
                align-items: center;
                justify-content: center;
                position: relative;
                background-color: rgb(0 0 0 / 50%);
                transition: background-color 400ms, -webkit-box-shadow 400ms;
                transition: background-color 400ms, box-shadow 400ms;
                transition: background-color 400ms, box-shadow 400ms,
                  -webkit-box-shadow 400ms;

                &::before {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 5px;
                  z-index: -1;
                  background-color: @secondary-color;
                  transition: height 400ms;
                  border-radius: 5px 5px 0 0;
                }

                &:after {
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  width: calc(100% - 1rem);
                  height: 30px;
                  margin: auto;
                  filter: blur(8px);
                  transform: scaleY(0);
                  transform-origin: top;
                  transition: all 0.5s ease;
                  z-index: -3;
                  opacity: 0;
                  box-shadow: 0 8px #424242;
                }
                img {
                  width: 80%;
                  height: auto;
                  display: block;
                  margin: auto;
                  transform: translateY(0px);
                  transition: transform 0.5s ease-in-out;
                }

                p {
                  margin: 0;
                  opacity: 0;
                  transform: translateY(30px);
                  transition: opacity 0.5s ease-in-out,
                    transform 0.5s ease-in-out;
                }

                &:hover,
                &.active {
                  &:before {
                    height: 15px;
                  }

                  &:after {
                    transform: none;
                    opacity: 1;
                  }
                  p {
                    opacity: 1;
                    transform: translateY(10px);
                  }
                  img {
                    transform: translateY(-8px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .tabs_container {
      margin-top: 2rem;
      display: flex;
      flex-wrap: wrap;

      .tab_img {
        width: 50%;
        padding-right: 50px;
        padding-top: 0;
        img {
          box-sizing: border-box;
          width: 100%;
          height: auto;
          max-height: 400px;
          object-fit: fill;
          margin: 0;
          display: block;
          border: 1px solid fade(@textWhite, 35%);
          border-radius: 15px;
          padding: 25px;
          background-color: rgb(0 0 0 / 50%);
        }
      }

      .tab_content {
        width: 50%;
        // height: 400px;
        // overflow-y: auto;
        border: 1px solid fade(@textWhite, 35%);
        padding: 25px 15px 25px 25px;
        border-radius: 15px;
        background-color: rgb(0 0 0 / 50%);
        color: @textWhite;
        text-align: justify;
        .customScrollbars > div:first-child {
          padding-right: 40px;
          overflow-x: hidden !important;
        }

        p {
          line-height: 1.5;
          margin-bottom: 10px;
          text-align: justify;
          color: @textWhite;
          text-align: justify;
        }

        a {
          color: @textWhite;
          margin: 0 5px;
          text-decoration: underline;
          transition: all 0.5s ease;

          &:hover {
            color: @primary-color;
          }
        }

        ul {
          margin: 0.5em 0 1.5em;
          padding: 0;

          li {
            // font-size: 1rem;
            line-height: 20px;
            margin-bottom: 10px;
            position: relative;
            padding-left: 15px;
            color: @textWhite;
            text-align: justify;

            &:before {
              content: "";
              display: inline-block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #fff;
              position: absolute;
              top: 6px;
              left: 0;
            }
          }

          ul {
            padding: 0 0 0 30px;
          }
        }

        ol {
          padding: 0 0 0 15px;
          margin: 0.5em 0 1.5em;

          li {
            // font-size: 1rem;
            line-height: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .ourServices_wrap {
      .slider_wrap {
        .btn_wrap {
          width: 13%;
          gap: 0.75rem;
        }

        .slick_wrap {
          //   width: 87%;
          .slick-track .slick-slide {
            padding: 0.75rem;
            padding-bottom: 25px;

            > div .item_wrap {
              padding: 0.75rem;
              min-height: 150px;
              font-size: 1rem;
            }
          }
        }
      }

      .tabs_container {
        margin-top: 1rem;

        .tab_content {
          // padding: 2.5rem;

          p {
            line-height: 1.5;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .ourServices_wrap {
      .tabs_container {
        .tab_img {
          width: 100%;
          padding: 0 0 30px;
        }

        .tab_content {
          width: 100%;
        }
      }

      .slider_wrap {
        .btn_wrap {
          width: 100%;
          gap: 0.75rem;
        }

        .slick_wrap {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .ourServices_wrap .slider_wrap .slick_wrap .slick-track .slick-slide {
      padding: 0.5rem;
      padding-bottom: 25px;

      > div .item_wrap {
        padding: 0.5rem;
        min-height: 120px;
        img {
          width: 70%;
        }
        &:hover,
        &.active {
          p {
            transform: translateY(5px);
          }
          img {
            transform: translateY(-8px);
          }
        }
      }
    }
  }
}

.clientsSay_section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;

  .clientsSay_slick {
    .slick-dots li button:before {
      display: none;
    }
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
        }
      }
    }

    .item {
      padding: 0.5rem;
      height: 100%;

      .item_wrap {
        background-color: #fff;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .logo_wrap {
          padding: 1rem 1rem;
          padding-top: 2rem;
          padding-bottom: 0.25rem;
          //   height: 80px;
          display: flex;
          align-items: center;

          img {
            max-height: 80px;
          }
        }

        .content {
          padding: 1rem 1.25rem;
          color: #515151;
          line-height: 1.5;
          font-weight: 500;
          margin-bottom: 1rem;
          height: 100%;
          text-align: justify;
        }

        .title_wrap {
          border-top: solid 1px #ddd;
          padding: 1.25rem 1rem;
          background-repeat: no-repeat;
          background-position: calc(100% - 1.25rem) center;
          background-size: 40px;

          h3,
          h4 {
            font-size: 1rem;
            color: #00235a;
            width: 85%;
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .clientsSay_slick .item .item_wrap {
      .logo_wrap {
        // height: 75px;
        img {
          max-height: 75px;
        }
      }

      .title_wrap {
        background-size: 38px;
        padding: 1rem;
      }
    }
  }

  @media (max-width: 1199px) {
    .clientsSay_slick .item .item_wrap {
      .logo_wrap {
        // height: 70px;
        img {
          max-height: 70px;
        }
      }

      .content {
        margin-bottom: 0;
      }

      .title_wrap {
        h3,
        h4 {
          font-size: 1rem;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .clientsSay_slick .item .item_wrap {
      .logo_wrap {
        // height: 60px;
        img {
          max-height: 60px;
        }
      }
    }
  }
}

.ourClients_section {
  .ant-row {
    align-items: center;
  }

  .sc_title .btn_wrap {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;
  }

  .clients_slick {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;
        // & > div {
        //   height: 100%;
        // }
      }
    }

    &.slick-initialized {
      .slick-slide {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }

    .item_wrap {
      padding: 1rem;
      height: 100%;

      //   display: grid !important;
      //   grid-template-columns: 1fr;
      //   grid-template-rows: 1fr 1fr;
      //   gap: 2rem;
      .img_wrap {
        border: 2px solid #333;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 2rem 1rem;
        text-decoration: none;
        transition: all 0.5s ease;

        img {
          display: block;
          transition: all 0.5s ease;
        }
        &:focus,
        &:focus-within,
        &:hover {
          cursor: pointer;
          box-shadow: 0 0px 22px 2px rgb(255 255 255 / 5%),
            0 2px 22px 2px rgb(255 255 255 / 5%);
          border: 2px dashed @secondary-color;

          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .sc_title .btn_wrap {
      gap: 0.75rem;
      margin-top: 2.5rem;
    }

    .clients_slick .item_wrap {
      //   gap: 1.5rem;
      padding: 0.75rem;

      .img_wrap {
        padding: 1.5rem 1rem;
        border-radius: 15px;

        img {
          max-height: 100px;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .clients_slick .item_wrap {
      //   gap: 1.25rem;
      padding: 0.625rem;

      .img_wrap {
        padding: 1.25rem 1rem;
        border-radius: 12px;

        img {
          max-height: 80px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .sc_title .btn_wrap {
      justify-content: center;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}

.trackCourier_section {
  position: relative;

  .SectionBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.1;
    background-attachment: fixed;
    pointer-events: none;
  }

  .trackCourier_wrap {
    margin-top: 3rem;
    margin-bottom: 2rem;

    .radio_wrap .ant-radio-group {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      .ant-radio-wrapper {
        margin: 0 1rem;
        align-items: center;

        .ant-radio {
          top: 0;

          .ant-radio-inner {
            width: 22px;
            height: 22px;
            background-color: transparent;
            border: 1px solid #fff;

            &:after {
              width: 20px;
              height: 20px;
              margin-top: -10px;
              margin-left: -10px;
              background-color: @secondary-color;
            }
          }

          &.ant-radio-checked {
            .ant-radio-inner {
              border-color: #fff;
            }
          }
        }
      }
    }

    .tabs_container {
      .tab_content {
        max-width: 850px;
        margin: auto;
        position: relative;
        height: 75px;
        border-radius: 8px;
        overflow: visible;
        background-color: @secondary-color;

        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-input {
          background-color: #f4f4f6;
          height: 75px;
          width: calc(100% - 200px);
          border: 0;
          border-radius: 0;
          padding: 1rem 1.75rem;
          color: @secondary-color;
          font-weight: 600;
          font-size: 1.25rem;
          border-radius: 8px 0 0 8px;
        }

        .btn {
          position: absolute;
          top: 0;
          right: 0;
          width: 200px;
          height: 100%;
          border-radius: 0;
          transform: none;
          background-color: @secondary-color;
          color: #fff;
          border: 0;
          box-shadow: none;
          font-size: 1rem;
          cursor: pointer;
          border-radius: 0 8px 8px 0;

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .trackCourier_wrap .tabs_container .tab_content {
      max-width: 750px;
      height: 65px;

      .ant-input {
        padding: 1rem 1.5rem;
        font-size: 1.125rem;
        height: 65px;
        width: calc(100% - 180px);
      }

      .btn {
        width: 180px;
      }
    }
  }

  @media (max-width: 1199px) {
    .trackCourier_wrap .tabs_container .tab_content {
      max-width: 680px;
      height: 60px;

      .ant-input {
        padding: 1rem 1.5rem;
        font-size: 1.125rem;
        height: 60px;
        width: calc(100% - 160px);
      }

      .btn {
        width: 160px;
      }
    }
  }

  @media (max-width: 991px) {
    .trackCourier_wrap .tabs_container .tab_content {
      max-width: 600px;
      height: 55px;

      .ant-input {
        padding: 1rem 1.5rem;
        font-size: 1.125rem;
        height: 55px;
      }
    }
  }

  @media (max-width: 767px) {
    .trackCourier_wrap {
      margin-top: 2rem;
      margin-bottom: 1rem;

      .radio_wrap .ant-radio-group {
        margin-bottom: 1rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;

        .ant-radio-wrapper {
          flex-wrap: wrap;
          justify-content: center;

          .ant-radio {
            .ant-radio-inner {
              width: 18px;
              height: 18px;

              &:after {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                margin-left: -8px;
              }
            }

            + span {
              text-align: center;
            }
          }
        }
      }

      .tabs_container .tab_content {
        max-width: 500px;
        height: 50px;

        .ant-input {
          padding: 0.5rem 1rem;
          font-size: 0.875rem;
          height: 50px;
          width: calc(100% - 120px);
        }

        .btn {
          width: auto;
          min-width: unset;
          font-size: 0.875rem;
          padding: 0.5rem;
          margin: 0;
        }
      }
    }
  }
}

.coreValue_section {
  .coreValue_slick {
    margin-top: 3rem;

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
        }
      }
    }

    .item_wrap {
      position: relative;
      padding: 0.25rem;
      height: 100%;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        opacity: 0.7;
        transition: opacity 400ms;
      }

      .defaultImg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0.25rem;

        span {
          width: 100%;
          height: 100%;
          display: inline-block;
          background: #1e1e1e;
        }
      }

      .content_wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: calc(100% - 0.5rem);
        z-index: 2;

        .content {
          padding: 1.25rem;
          transform: translateY(100%);
          opacity: 0;
          transition: opacity 300ms, -webkit-transform 600ms;
          transition: opacity 300ms, transform 600ms;
          transition: opacity 300ms, transform 600ms, -webkit-transform 600ms;

          h3 {
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            text-transform: capitalize;
          }

          p {
            text-align: justify;
            line-height: 1.6;
            margin-bottom: 0.5em;
          }
        }

        .btn_wrap {
          position: relative;
          display: block;

          p {
            display: block;
            background-color: #fff;
            padding: 1.5rem;
            font-size: 1.5rem;
            font-weight: 500;
            color: @secondary-color;
            text-decoration: none;
            text-transform: capitalize;
            max-width: 75%;
            width: 100%;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            overflow: visible;
            transition: color 400ms;
            position: relative;
            z-index: 2;
            margin-bottom: 1.5rem;

            &:before {
              content: "";
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
              z-index: -1;
              width: 35%;
              height: 4px;
              background-color: @secondary-color;
              transition: all 0.5s ease-in-out;
              border-radius: 50px;
            }
          }

          .plus_btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.25rem;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background-color: #fff;
            position: absolute;
            left: calc(75% - 25px);
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            opacity: 0;
            transition: opacity 300ms 200ms;
          }

          // &:hover {
          // 	p {
          // 		color: #fff;
          // 		transition: color 400ms 200ms;
          // 		&:before {
          // 			width: 120%;
          // 			height: 100%;
          // 			transition: width 200ms, height 300ms 200ms;
          // 		}
          // 	}
          // }
        }
      }

      &:hover {
        & > img {
          opacity: 0.15;
        }

        .content_wrap {
          .content {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
            transition: opacity 400ms 100ms, -webkit-transform 400ms;
            transition: opacity 400ms 100ms, transform 400ms;
            transition: opacity 400ms 100ms, transform 400ms,
              -webkit-transform 400ms;
          }
        }

        .btn_wrap {
          p {
            color: #fff;
            // background-color: @secondary-color;
            transition: color 400ms 300ms;

            &:before {
              width: 102%;
              height: 100%;
              border-radius: 0 50px 50px 0;
              transition: all 0.5s ease-in-out;
            }
          }

          .plus_btn {
            transition: opacity 400ms 300ms;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .coreValue_slick .item_wrap .content_wrap {
      .content {
        padding: 1rem;

        h3 {
          font-size: 1.25rem;
        }
      }

      .btn_wrap {
        p {
          padding: 0.95rem;
          font-size: 1.25rem;
        }

        .plus_btn {
          width: 40px;
          height: 40px;
          left: calc(75% - 20px);
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .coreValue_slick .item_wrap .content_wrap {
      .content {
        padding: 0.75rem;

        h3 {
          font-size: 1.125rem;
        }
      }

      .btn_wrap {
        p {
          padding: 1rem;
          font-size: 1rem;
          margin-bottom: 1.25rem;
        }

        .plus_btn {
          width: 35px;
          height: 35px;
          left: calc(75% - 17.5px);
        }
      }
    }
  }

  @media (max-width: 767px) {
    .coreValue_slick {
      margin-top: 1rem;
    }
  }
}

.missionVision_section {
  .tab_content {
    display: none;
    animation-name: fadeInFor1;
    animation-duration: 400ms;

    & > * {
      &:nth-child(1) {
        animation-name: fadeInFor1;
        animation-duration: 500ms;
      }

      &:nth-child(2) {
        animation-name: fadeInFor1;
        animation-duration: 800ms;
      }
    }

    &.active {
      display: block;
    }
  }

  .col_content {
    .wave_shape {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      background-color: #fff;
      padding: 0.5rem;
      z-index: 1;
      border-radius: 40% 60% 70% 30%/40% 50% 60% 50%;
      overflow: hidden;
      transition: -webkit-transform 500ms;
      transition: transform 500ms;
      transition: transform 500ms, -webkit-transform 500ms;
      animation: morph 8s linear infinite;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      @keyframes morph {
        0%,
        100% {
          border-radius: 75% 23% 31% 66% / 62% 62% 33% 29%;
        }

        34% {
          border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        }

        67% {
          border-radius: 27% 82% 41% 68% / 64% 71% 32% 44%;
        }
      }

      img {
        position: relative;
        z-index: 2;
        max-width: 50px;
        display: block;
      }
    }
  }

  .col_video {
    .video_wrap {
      position: relative;

      .img {
        width: 100%;
        display: block;
      }

      .video_play {
        display: block;
        width: 80px;
        aspect-ratio: 1;
        border-radius: 50px;
        background-color: #fff;
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin: 0;

        &:before {
          content: "";
          position: absolute;
          z-index: 0;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          display: block;
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 50%;
          -webkit-animation: pulse-border 1800ms ease-out infinite;
          animation: pulse-border 1800ms ease-out infinite;
        }

        span {
          position: absolute;
          z-index: 1;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          display: block;
          width: 100%;
          height: 100%;
          background: #fff;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 26px;
        }

        @keyframes pulse-border {
          0% {
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            opacity: 1;
          }

          100% {
            transform: translateX(-50%) translateY(-50%) translateZ(0)
              scale(1.65);
            opacity: 0;
          }
        }
      }
    }

    .tab_wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      background-color: #fff;
      height: 80px;
      margin: 0 3rem;
      margin-top: -40px;
      position: relative;
      z-index: 2;
      padding: 0.75rem 0;

      .tab {
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.275rem;
        overflow: visible;
        font-weight: 600;
        height: 100%;
        border: none;
        padding: 0;
        border-radius: 0;
        border-right: solid 1px #000;
        background-color: transparent;
        position: relative;
        cursor: pointer;

        &::before {
          content: "";
          height: 3px;
          width: 100%;
          background-color: @primary-color;
          position: absolute;
          bottom: -0.75rem;
          left: 0;
          transform: scaleX(0);
          transition: -webkit-transform 400ms;
          transition: transform 400ms;
          transition: transform 400ms, -webkit-transform 400ms;
        }

        &:last-child {
          border-right: 0;
        }

        &.active {
          background-color: transparent;

          &::before {
            transform: none;
          }
        }

        img {
          max-width: 40px;
          display: block;
          margin-right: 0.75rem;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .col_content .wave_shape {
      width: 80px;
      height: 80px;
      margin-bottom: 1.5rem;

      img {
        max-width: 35px;
      }
    }

    .col_video {
      .video_wrap .video_play {
        width: 60px;

        span {
          background-size: 20px;
        }
      }

      .tab_wrap {
        height: 65px;
        margin: 0 2rem;
        margin-top: -32.5px;
        padding: 0.5rem 0;

        .tab {
          font-size: 1.125rem;

          &::before {
            bottom: -0.5rem;
            height: 2px;
          }

          img {
            max-width: 32px;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .col_content .wave_shape {
      width: 70px;
      height: 70px;
      margin-bottom: 1rem;

      img {
        max-width: 30px;
      }
    }

    .col_video .tab_wrap {
      height: 60px;
      margin: 0 1.25rem;
      margin-top: -30px;
      padding: 0.5rem 0;

      .tab {
        font-size: 1rem;

        &:before {
          bottom: -0.5rem;
          height: 2px;
        }

        img {
          max-width: 30px;
          margin-right: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .col_content .wave_shape {
      margin: auto;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 767px) {
    .col_video {
      .video_wrap .video_play {
        width: 50px;

        span {
          background-size: 16px;
        }
      }

      .tab_wrap {
        margin: 0 1rem;
        height: 50px;
        margin-top: -25px;
        padding: 0.5rem 0;

        .tab {
          font-size: 1rem;

          &::before {
            bottom: -0.5rem;
            height: 2px;
          }

          img {
            max-width: 28px;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}

@keyframes fadeInFor1 {
  0%,
  50% {
    opacity: 0;
  }

  75% {
    opacity: 0.25;
  }

  85% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.sub_text {
  p {
    text-align: justify;
  }
}

//publicNotice

.publicNotice {
  width: 95%;
  max-width: 1000px;
  left: 50%;
  transform: translate(-50%, 0);
  .ant-drawer-mask {
    display: none;
  }
  .ant-drawer-content {
    background-color: fade(#191919, 95%);
    border: 1px solid #353535;
    border-radius: 15px;
    box-shadow: -5px -5px 13px fade(#929292, 16%);
    position: relative;
    overflow: visible;
    .ant-drawer-body {
      overflow: visible;
      .ant-btn.ant-btn-default.close {
        position: absolute;
        top: -15px;
        right: -15px;
        z-index: 5;
        min-width: unset;
        min-height: unset;
        width: 43px;
        height: 43px;
        border-radius: 43px;
        background-color: transparent;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: scale 0.5s ease-in-out;
        svg {
          width: 100%;
          height: 100%;
        }
        &:hover,
        &:focus-within {
          scale: 1.08;
        }
      }

      .title {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: 20px;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, 0);
        width: 85%;
        max-width: 270px;
        height: 55px;
        font-size: 35px;
        line-height: 1.3;
        color: @primary-color;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        svg {
          width: 110%;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: -1;
          transform: translate(-50%, -50%);
        }
      }

      .publicNoticeSlider {
        margin-top: 60px;
        .box {
          padding: 10px 10px 0;
          .heading {
            font-size: 2rem;
            font-weight: 400;
            font-style: italic;
            letter-spacing: 1px;
            line-height: 1.8;
            color: @textWhite;
            text-align: left;
          }
          .content {
            font-size: 1.3;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 1.5;
            color: @textWhite;
            text-align: left;
            word-break: break-word;
            a {
              color: @primary-color;
            }
            strong,
            b {
              font-weight: 900;
              color: @white;
            }
          }
        }
        .slick-dots {
          margin: 0 auto -15px;
          list-style: none;
          button {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .ant-drawer-content-wrapper {
      height: auto !important;
    }
    .ant-drawer-content {
      background-color: fade(#191919, 98%);
      .ant-drawer-body {
        padding: 15px;
        .ant-btn.ant-btn-default.close {
          scale: 0.6;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
        }
        .publicNoticeSlider {
          margin-top: 45px;
          .box {
            padding: 0 10px;
            .heading {
              font-size: 1.4rem;
              letter-spacing: normal;
              line-height: 1;
              margin-bottom: 10px;
            }
            .content {
              font-size: 0.9rem;
              letter-spacing: normal;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}

video {
  &::-moz-media-controls,
  &::-webkit-media-controls {
    display: none !important;
  }
}
