.bg_img {
  position: relative;
}

.login_section {
  .bannerImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.1;
    pointer-events: none;
    z-index: 1;
  }

  .card_wrap {
    padding: 3rem;
    background-color: transparent;
    background-repeat: repeat no-repeat;
    // background-size: 90% auto;
    background-size: auto 150px;
    animation: moveBG 60s infinite linear;

    &::after {
      background-color: @secondary-color;
    }

    &.v1_card_wrap {
      background-color: fade(@FullBlackColor, 80%);

      .col_custom {
        color: @textWhite;

        .img {
          max-width: 250px;
          display: block;
          margin-bottom: 1em;
        }

        .titleBox {
          margin-bottom: 1.5em;

          .title {
            color: @textWhite;
            font-size: 2em;
            font-weight: 400;
            margin-bottom: 0;
            padding: 0;
          }

          p {
            color: @textWhite;
            margin-bottom: 0.5em;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .input_group p,
        .input_group .ant-checkbox-wrapper {
          color: @textWhite;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
        }

        .loginOTPBtn {
          color: @secondary-color;
          transition: all 0.5s ease;
          cursor: pointer;

          &:hover {
            color: @primary-color;
          }
        }

        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input,
        .ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
          ).ant-input:hover {
          background-color: @textWhite;
        }

        .viewInPc {
          display: block;
        }

        .viewInMobile {
          display: none;
        }

        @media (max-width: 991px) {
          .titleBox {
            .title {
              font-size: 1.56em;
            }

            p {
              font-size: 14px;
            }
          }
        }

        @media (max-width: 767px) {
          .titleBox {
            .title {
              font-size: 1.6em;
            }
          }

          .viewInPc {
            display: none;
          }

          .viewInMobile {
            display: block;
          }
        }
      }
    }

    .btn {
      min-width: 150px;
      padding: 0.75em 1em;
    }

    &.register_card_wrap {
      padding: 4rem 3rem;

      .ant-form {
        margin-top: 40px;
      }
    }

    .col_img {
      background-color: fade(@FullBlackColor, 80%);

      .col_wrap {
        text-align: center;

        h3 {
          color: @textWhite;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.4;
          text-align: center;
          margin-bottom: 0.75em;
          padding: 0 30px 20px;
        }

        .img_wrap .img {
          display: block;
          margin: auto;
          pointer-events: none;
          user-select: none;
        }

        .pointList {
          width: 95%;
          padding: 0;
          margin: 0 auto;
          list-style: none;
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 30px 20px;
          text-align: left;

          li {
            width: 100%;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            color: @textWhite;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 10px;
            word-break: break-word;
            padding-right: 20px;

            .rightTic {
              fill: @textWhite;
            }
            img,
            svg {
              width: 24px;
              min-width: 24px;
              height: 24px;
              margin: 0;
              padding: 0;

              path {
                fill: @textWhite;
              }
            }
          }
        }

        @media (max-width: 1560px) {
          .pointList {
            li {
              font-size: 1rem;
              gap: 10px;
            }
          }
        }

        @media (max-width: 1200px) {
          h3 {
            font-size: 1.8rem;
            padding: 0 20px 20px;
          }
        }

        @media (max-width: 991px) {
          h3 {
            font-size: 1.5rem;
            padding: 0 0 20px;
          }

          .pointList {
            grid-template-columns: 1fr;
            margin: auto;
            width: 80%;
          }
        }

        @media (max-width: 767px) {
          .pointList {
            grid-template-columns: 55% 45%;
            margin: auto;
            width: 100%;
          }
        }

        @media (max-width: 520px) {
          h3 {
            margin: 10px auto 0px;
          }

          .pointList {
            grid-template-columns: 1fr;
            width: 80%;
          }
        }
      }
    }

    .col_form {
      background-color: fade(@secondary-color, 80%);

      .col_wrap {
        width: 100%;
        padding: 0 5rem;

        h3 {
          color: @textWhite;
          font-size: 2rem;
          font-weight: 600;
          line-height: 1.4;
          text-align: left;
          margin-bottom: 0;
          padding: 0 0 20px;
        }

        .location_form {
          .input_group {
            margin-bottom: 1.5rem;
            position: relative;

            &:nth-child(1) {
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: -2rem;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border: solid 1px #fff;
                background-color: @secondary-color;
                z-index: 2;
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: calc(-2rem + 6.4935064935px);
                width: 2px;
                height: calc(100% + 1.5rem);
                border-radius: 10px;
                border-left: dashed 1px #fff;
                z-index: 1;
              }
            }

            &:nth-child(2) {
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: -2rem;
                -webkit-transform: translateY(-50%) rotate(-45deg);
                transform: translateY(-50%) rotate(-45deg);
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border-bottom-left-radius: 0;
                border: solid 1px #fff;
                background-color: @secondary-color;
                z-index: 2;
              }

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: calc(-2rem + 4.4px);
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 6px;
                height: 6px;
                border-radius: 10px;
                border: solid 1px #fff;
                z-index: 2;
              }
            }

            .ant-input {
              border-radius: 0px;
              font-size: 1rem;
              font-weight: 500;
              padding: 0.75em 1em;
              max-width: unset;
            }

            p {
              font-size: 14px;
              color: #fff;
              font-weight: normal;
            }

            .citySuffix {
              position: absolute;
              top: 0;
              right: 10px;
              color: #000;
              font-size: 14px;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }
          .ant-form-item-explain-error {
            background-color: transparent;
            color: #fff;
            font-size: 0.8rem;
            font-weight: 600;
            letter-spacing: 0.03em;
            line-height: 1;
            padding: 3px 4px;
            margin: 0 0 8px;
            text-align: left;
          }
        }

        @media (max-width: 1200px) {
          h3 {
            font-size: 1.8rem;
            padding: 0 20px 20px;
          }
        }

        @media (max-width: 991px) {
          padding: 0 2rem 0 3rem;

          h3 {
            font-size: 1.5rem;
            padding: 0 0 20px;
          }
        }

        @media (max-width: 520px) {
          padding: 0 0 0 30px;

          h3 {
            margin: 10px auto 0px;
          }
        }
      }
    }
  }

  .ant-input-password {
    &.ant-input-affix-wrapper {
      border: 0;
      width: 100%;
      // max-width: 400px;
      border: 1px solid #ccc;
      border-radius: 4px;

      > input.ant-input {
        padding: 0.75em 1em;
      }
    }
  }

  .input_group {
    margin-bottom: 1em;

    .ant-input-password {
      &.ant-input-affix-wrapper {
        border: 0;
        // max-width: 400px;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    }

    .ant-checkbox-wrapper {
      color: #000;
    }

    .ant-input {
      // max-width: 400px;
      width: 100%;
      color: #000;
      font-weight: 500;
      font-size: 1.25em;
      height: 55px;
      padding: 0.5em 1em;
      border-color: #ccc;

      &:focus {
        border-color: @secondary-color;
        box-shadow: none;
      }
    }

    p {
      color: #333;
      font-weight: 500;

      a {
        display: inline-block;
        text-decoration: none;
        color: @primary-color;
        transition: color 400ms;
      }
    }

    &.btn_wrap {
      margin-top: 1.5rem;
    }
  }

  .ant-input {
    // max-width: 400px;
    width: 100%;
    color: #000;
    font-weight: 500;
    font-size: 1.25em;
    padding: 0.75em 1em;
    border-color: #ccc;

    &:focus {
      border-color: @secondary-color;
      box-shadow: none;
    }
  }

  .title_box {
    text-align: center;

    h2,
    h3 {
      font-size: 1.75rem;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 1em;

      strong {
        display: block;
        font-size: 160%;
        font-weight: 400;
      }
    }
  }

  &.eBook_login {
    .card_wrap {
      padding: 0;

      &:after {
        background-color: #fff;
      }

      > .ant-row > * {
        padding: 4rem 2rem !important;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 520px) {
          padding: 2rem !important;
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .card_wrap {
      &.v1_card_wrap .col_custom .img {
        max-width: 200px;
      }

      .btn {
        min-width: 130px;
        padding: 0.6em 1em;
        min-height: 1px;
      }
    }

    .input_group .ant-input {
      font-size: 1em;
      height: 45px;
    }

    .title_box {
      h2,
      h3 {
        font-size: 1.35rem;
      }
    }

    .ant-input {
      font-size: 1em;
    }
  }

  @media (max-width: 1199px) {
    .card_wrap {
      &::before {
        height: 8px;
      }

      &.v1_card_wrap .col_custom {
        .img {
          max-width: 160px;
        }

        .title {
          h3,
          h4 {
            font-size: 1.5rem;
          }

          p {
            font-size: 13px;
          }
        }
      }
    }

    .input_group p {
      font-size: 13px;
    }

    .title_box {
      h2,
      h3 {
        font-size: 1.25rem;
      }
    }
  }

  @media (max-width: 991px) {
    .card_wrap.v1_card_wrap .col_custom {
      text-align: center;

      // &:first-child {
      // 	border-bottom: solid 1px #ddd;
      // 	padding-bottom: 1rem;
      // 	margin-bottom: 1.5rem;
      // }
      .img {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .title_box {
      h2,
      h3 {
        font-size: 1.125rem;
      }
    }
  }

  @media (max-width: 767px) {
    .card_wrap {
      padding: 2rem 1.5rem;
      background-size: 700px auto;
      animation: moveBGsm 20s infinite linear;

      &::before {
        width: 80%;
      }

      &.v1_card_wrap .col_custom {
        .img {
          max-width: 140px;
        }

        .title {
          h3,
          h4 {
            font-size: 1.25rem;
          }
        }
      }
    }

    .title_box {
      h2,
      h3 {
        font-size: 1rem;
      }
    }
  }
}

.card_wrap {
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 75%;
    height: 10px;
    // background-color: @secondary-color;
    background-color: #2b2c7a;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &.pincode {
    &::before {
      // 	width: 37.5%;
      // transform: translateX(-99%);
      // border-top-right-radius: 0;
      background-color: #2b2c7a;
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: -22.5px;
    right: -22.5px;
    width: 45px;
    height: 45px;
    background-color: #fff;
    transform: rotate(-45deg);
  }

  .card_title {
    position: absolute;
    top: 0.5rem;
    left: 0;
    background-color: @secondary-color;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0.5em 1em;
    z-index: 1;

    p {
      margin: 0;
    }
  }

  &.max-1420 {
    max-width: 1420px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1560px) {
    .card_title {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 1199px) {
    .card_title {
      font-size: 1.125rem;
    }
  }

  @media (max-width: 767px) {
    .card_title {
      font-size: 1rem;
    }
  }
}

.OTP_input {
  .ant-form-item-control-input-content > div {
    display: flex;
    gap: 1rem;

    .otp-box {
      width: 60px;
      text-align: center;

      //   flex-wrap: wrap;
      input {
        width: 100% !important;
        color: #000;
        font-weight: 500;
        font-size: 1.25em;
        padding: 0.75em 1em;
        box-shadow: none;
        border: 1px solid #ccc;
        height: auto;
        border-radius: 5px;

        &:focus {
          border-color: @secondary-color;
          box-shadow: none;
          outline: 0;
        }
      }

      span {
        display: none;
      }
    }
  }

  @media (max-width: 1560px) {
    .ant-form-item-control-input-content > div .otp-box input {
      font-size: 1em;
    }
  }

  @media (max-width: 991px) {
    .ant-form-item-control-input-content > div {
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    .ant-form-item-control-input-content > div {
      gap: 0.5em;
    }
  }
}

.loginModalWrap {
  padding: 0 20px;

  .loginTypes {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      color: #000;
      padding: 10px 20px;
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom: 2px solid @primary-color;
      }
    }
  }

  .ant-form-item-label label {
    color: #000 !important;
  }

  .ant-input {
    color: #000;
  }

  .captchaWrap .captchaCode input {
    color: @secondary-color;
  }
}

@keyframes moveBG {
  0% {
    background-position: bottom right;
  }

  100% {
    background-position: bottom left;
  }
}

@keyframes moveBGsm {
  0% {
    background-position: bottom left;
  }

  100% {
    background-position: bottom right;
  }
}
