.newsletterSection {
  .tabs_container {
    font-weight: normal;
    display: none;
    .newsletterContent {
      .heading,
      .sub_heading {
        display: block;
        color: @primary-color;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.3;
        padding: 10px;
        margin: 0;
      }
      .sub_heading {
        font-size: 22px;
      }
      .titles {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
        align-items: stretch;
        justify-content: stretch;
        gap: 80px;
        isolation: isolate;
        position: relative;
        margin-bottom: 50px;
        &::before {
          content: "";
          border: 2px dashed #484848;
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: -1;
        }
        .box {
          background-color: @textWhite;
          color: @primary-color;
          border-radius: 0 0 20px 0;
          font-size: 16px;
          font-weight: 600;
          padding: 20px 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          max-width: 300px;
        }
      }
      .content {
        padding: 20px 10px;
        color: @textWhite;
        font-size: 16px;
        line-height: 1.5;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            background-image: url("../../arrow.png");
            background-repeat: no-repeat;
            background-position: 0 5px;
            background-size: 8px 12px;
            padding-left: 20px;
            margin-bottom: 15px;
          }
        }
      }
    }
    &.active {
      display: block;
    }

    @media (max-width: 991px) {
      .newsletterContent {
        .titles {
          grid-template-columns: repeat(2, 1fr);
          gap: 30px 30px;
          margin-bottom: 30px;
          &::before {
            display: none;
          }
          .box {
            width: 100%;
            max-width: unset;
          }
        }
      }
    }
  }
}
