.network_section {
  .input_group_blk {
    margin-block: 4rem 1rem;
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   width: 75%;
    //   height: 1px;
    //   background-image: linear-gradient(90deg, #000, #fff, #000);
    //   filter: blur(1px) opacity(0.5);
    //   transform: translateX(-50%);
    // }
    .col_custom {
      &:first-child {
        padding-right: 4em !important;
      }
      &:last-child {
        padding-left: 4em !important;
      }
      .input_group {
        display: flex;
        align-items: center;
        position: relative;
        .ant-form-item {
          width: 100%;
          margin: 0;
          label {
            color: #fff;
            font-size: 1.125em;
            white-space: nowrap;
            margin-bottom: 0;
            margin-right: 1.5em;
          }
          .ant-input {
            padding: 0.75em 0.5em;
            padding-right: 2.5em;
          }
        }
        .btn {
          padding: 0.2em;
          width: 2.5em;
          height: 2.5em;
          min-width: unset;
          position: absolute;
          top: 18px;
          right: 0;
          border: 0;
          border-radius: 0;
          background-color: rgba(0, 0, 0, 0);
          filter: invert(1) opacity(0.75);
          transform: filter 400ms;
          svg.img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .input_group_blk .col_custom {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      &:first-child {
        padding-right: 15px !important;
      }
      &:last-child {
        padding-left: 15px !important;
      }
      .input_group .btn {
        padding: 0.5em;
      }
    }
  }

  @media (max-width: 767px) {
    .input_group_blk {
      margin-top: 2em;
      margin-bottom: 3em;
      padding-bottom: 1em;
      .col_custom .input_group label {
        font-size: 1em;
        margin-right: 1em;
      }
    }
  }

  @media (max-width: 575px) {
    .input_group_blk .col_custom {
      .input_group {
        .ant-form-item .ant-form-item-row {
          .ant-form-item-label {
            padding-bottom: 0;
            flex: unset;
            width: 70%;
          }
          .ant-form-item-control {
            width: 100%;
            flex: unset;
          }
        }
      }
    }
  }
}

.network_section {
  .ant-form.ant-form-vertical {
    .ant-row.ant-form-item-row {
      flex-direction: column !important;
      align-items: flex-start !important;
      justify-content: flex-start;
      .ant-form-item-control {
        width: 100%;
      }
    }
  }
}

.CenterList {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px 30px;
    li {
      justify-self: start;
      align-self: center;
      span {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        line-height: 1.3rem;
        word-wrap: break-word;
      }
      &:hover,
      &:focus-within {
        span {
          color: @primary-color;
        }
      }
    }
  }
}

.ant-select-item-empty {
  color: currentColor !important;
}
