.tabs_wrapper {
  .tab_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    width: auto;
    margin-bottom: 3rem;

    .tab {
      text-align: center;
      list-style: none;
      color: rgba(255, 255, 255, 0.8);
      border: solid 1px #3d3d3d;
      padding: 0;
      margin-right: -1px;
      overflow: hidden;
      cursor: pointer;
      min-width: 8em;
      transition: background-color 400ms;

      span,
      a {
        display: block;
        padding: 0.75em 1em;
        color: @textWhite;
        w &:hover,
        &:focus-within {
          color: @textWhite !important;
        }
      }

      &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      &.active {
        background-color: @primary-color;

        a {
          &:hover,
          &:focus-within {
            color: @textWhite !important;
          }
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .tabs_container .tab_content {
    animation-name: fadeInFor1;
    animation-duration: 400ms;
  }
  @media (max-width: 599px) {
    .tab_wrap {
      flex-wrap: wrap;
      .tab {
        flex-basis: 100%;
        margin-bottom: -1px;

        &:first-child {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 0;
          border-right: solid 1px #3d3d3d;
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }
  }
}

.currentOpe_section {
  .currentOpe_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.5em;
    margin-top: 3em;

    .col_wrap {
      background-color: #fff;
      border-radius: 5px;
      color: #000;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 50%;
        height: 2px;
        background-color: @primary-color;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.75em 1em;

        h3,
        h4 {
          font-size: 1.25em;
          font-weight: 500;
          color: @primary-color;
          margin: 0;
        }

        .btn {
          font-size: 1em;
          padding: 0.4em 1.25em;
          margin: 0;
          box-shadow: none;
          border: 1px solid @primary-color;
          cursor: pointer;
        }
      }

      .content {
        padding: 0em 1em;
        // max-height: 260px;
        // overflow: auto;
        border-top: dotted 2px #ddd;

        ul {
          border-top: dotted 2px #ddd;
          margin: 0;
          list-style: none;
          padding: 0.75em 0;

          &:first-child {
            border-top: none;
          }

          li {
            list-style: none;
            margin-bottom: 1em;

            strong {
              font-size: 1.25em;
              font-weight: 500;
              margin-bottom: 0.15em;
              display: block;
            }

            p {
              font-size: 1em;
              margin-bottom: 0.5em;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1560px) {
    .currentOpe_grid {
      gap: 2.5em;
      //   .col_wrap .content {
      //     max-height: 240px;
      //   }
    }
  }

  @media (max-width: 1199px) {
    .currentOpe_grid {
      gap: 1.5em;

      .col_wrap {
        .title h4 {
          font-size: 1.125em;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .currentOpe_grid {
      grid-template-columns: 1fr;
      gap: 2em;
    }
  }
}

.input_group_blk {
  .input_group {
    margin: 1em auto 2em;

    .ant-form-item-label {
      padding-bottom: 0;

      > label {
        font-size: 1rem;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    &.textBlack {
      .ant-form-item-label label {
        color: #000;
        font-weight: 600;
      }
    }

    .ant-input {
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: #fff;
      font-size: 1.25rem;
      line-height: normal;
      padding: 0.5em 0;
      border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      transition: border-color 400ms;
      position: relative;

      &:focus {
        box-shadow: none;
        border-bottom-color: @secondary-color;
      }
    }
  }

  .ant-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border: 0;
      height: 45px;
      border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      border-radius: 0;
      transition: border-color 400ms;
      color: #fff;
      font-size: 1.25rem;
      padding-left: 0;
      box-shadow: none !important;

      .ant-select-selection-search-input {
        height: 45px;
      }

      .ant-select-selection-item {
        line-height: 45px;
      }
    }

    .ant-select-arrow {
      color: #fff;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: transparent;
    box-shadow: none;
    border-bottom-color: @secondary-color;
  }

  .ant-form-item-label > label {
    align-items: flex-start;
    &::before {
      margin-top: 6px;
    }
  }

  @media (max-width: 1560px) {
    .input_group {
      .ant-input {
        font-size: 1rem;
      }

      .ant-form-item-label > label {
        font-size: 0.875rem;
      }
    }

    .ant-select {
      &:not(.ant-select-customize-input) .ant-select-selector {
        height: 36px;
        font-size: 1rem;

        .ant-select-selection-search-input {
          height: 36px;
        }

        .ant-select-selection-item {
          line-height: 36px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .input_group {
      margin-bottom: 3em;

      .ant-input {
        padding: 0.35em 0;
      }
    }

    .ant-select {
      &:not(.ant-select-customize-input) .ant-select-selector {
        height: 31px;

        .ant-select-selection-search-input {
          height: 31px;
        }

        .ant-select-selection-item {
          line-height: 31px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .input_group {
      margin-bottom: 2.5em;
    }
  }
}

.forJob_lists {
  margin-top: 3em;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1.75em 0;
    justify-content: flex-start;

    li {
      display: block;
      list-style: none;
      width: 33.33%;
      position: relative;

      &:before {
        content: "";
        width: 6px;
        height: 6px;
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 6px;
        background-color: #fff;
        border-radius: 50%;
      }

      p {
        font-size: 1em;
        padding: 0.05em 1.5em;
        // border: solid 1px #3e3e3e;
        border-radius: 50px;
        margin: 0;
        display: inline-block;
        transition: background-color 400ms;
      }
    }
  }

  &.twoCol {
    width: 80%;
    margin: auto;
    ul {
      margin-top: 40px;
      li {
        width: 50%;
      }
    }
  }
  &.OneCol {
    width: 80%;
    margin: auto;
    ul {
      margin: 40px auto 0;
      width: 75%;
      li {
        width: 100%;
      }
    }
  }
  .info_content {
    margin-top: 4em;
    text-align: center;

    p {
      font-size: 1.25em;
      margin: 0;

      a {
        color: #fff;
        display: inline-block;
        border-radius: 50px;
        padding: 0;
        margin-left: 0.5em;
        text-decoration: underline;
        transition: background-color 400ms;
        font-size: 112%;
        font-weight: 500;
      }
    }
  }

  .jobNoteText {
    font-size: 16px;
    margin-bottom: 30px;
    color: rgb(255 255 255 / 70%);

    a {
      margin-left: 10px;
      font-size: 120%;
      text-decoration: underline;
    }
  }

  @media (max-width: 1560px) {
    ul li p {
      font-size: 1em;
    }

    .info_content p {
      font-size: 1.125em;
    }
  }

  @media (max-width: 991px) {
    ul {
      //   column-gap: 1em;
      li {
        width: 50%;
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      gap: 0.65em 0;

      li {
        width: 100%;

        // p {
        //   font-size: 13px;
        // }
      }
    }
    .jobNoteText {
      a {
        display: block;
        padding: 0;
        margin: 0;
      }
    }
    .info_content p {
      font-size: 1em;

      a {
        display: block;
        margin-top: 0;
        padding: 0.2em;
      }
    }
    &.twoCol {
      width: 100%;
    }
    &.OneCol {
      width: 100%;
      ul {
        width: 100%;
      }
    }
  }
}

.summary_wrap {
  .summary_card {
    margin-bottom: 1.5rem;
    border: solid 1px #3e3e3e;

    .card_title {
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5em 1em;

      > * {
        text-decoration: none;
        color: #000;
        font-weight: 500;
        margin: 0;
        font-size: 1.25em;
      }

      .edit_btn {
        display: block;
        background: transparent;
        border: 0;
        padding: 0;

        .img {
          width: 22px;
          aspect-ratio: 1;
        }
      }
    }

    .card_body {
      list-style: none;
      padding: 0.5em 1em;
      margin: 0;

      li {
        font-size: 1em;
        padding: 0.85em 0;
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px #3e3e3e;

        p {
          margin-bottom: 0;
        }

        &:last-child {
          border-bottom: none;
        }

        div p + p {
          margin-top: 5px;
        }

        .gst_wrap {
          width: 100%;

          .gst_form {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 1rem;

            .ant-input {
              background-color: rgba(0, 0, 0, 0);
              border: solid 1px #3e3e3e;
              color: #fff;
              border-radius: 2px;
              padding: 0.65em;
            }
          }
        }
      }
    }
  }
}

.channelPar_section {
  .summary_wrap .summary_card {
    .card_body {
      padding: 1em;

      .col_custom {
        &.brd_rt {
          border-right: solid 1px #3e3e3e;
        }

        .content {
          padding-right: 0.5em;

          p {
            margin-bottom: 0.75em;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        ul {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          margin: 0;
          padding: 0;

          &.col_2 {
            grid-template-columns: repeat(2, 1fr);
          }

          &.pl-1 {
            padding-left: 1em;
          }

          li {
            padding: 0.25em 1em;
            border: none;
            position: relative;
            justify-content: left;

            &::before {
              content: "";
              position: absolute;
              top: 0.65em;
              left: 0;
              width: 0.4em;
              height: 0.4em;
              border: solid 1px rgba(255, 255, 255, 0.75);
              border-top: none;
              border-left: none;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }

            p {
              margin: 0;

              span {
                color: rgba(255, 0, 0, 0.75);
              }
            }

            &.arrow_none {
              &:before {
                display: none;
              }
            }
          }
        }
      }

      &.px-0 {
        padding-left: 0rem;
        padding-right: 0rem;
      }
    }

    &.mb-0 {
      margin-bottom: 0;
    }

    &.border_none {
      border: none;
      padding: 0;
    }
  }

  @media (max-width: 991px) {
    .summary_wrap .summary_card .card_body .col_custom.brd_rt {
      border-right: none;
      margin-bottom: 1em;
    }
  }

  @media (max-width: 767px) {
    .summary_wrap .summary_card .card_body .col_custom ul {
      &.pl-1 {
        padding-left: 0em;
      }

      &.col_2 {
        grid-template-columns: 1fr;
      }
    }
  }
}

.ant-select-dropdown {
  background-color: #323232;
  color: @textWhite;

  .ant-select-item {
    min-height: 40px;
    line-height: 30px;
  }

  .ant-select-item-option-disabled {
    color: rgb(255 255 255 / 50%);
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgb(0 0 0 / 40%);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgb(0 0 0 / 20%);
}

.ant-picker {
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 45px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 0;
  transition: border-color 400ms;
  color: #fff;
  font-size: 1.25rem;
  padding-left: 0;

  &:hover {
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }

  &.ant-picker-focused {
    border-color: transparent;
    border-bottom-color: @secondary-color;
    box-shadow: none;
    background-color: transparent;
  }

  &.ant-picker-status-error,
  &.ant-picker-status-error:not([disabled]):hover {
    background-color: transparent;
  }

  .ant-picker:hover {
    border-color: rgba(255, 255, 255, 0.5);
  }

  .ant-picker-input > input {
    color: #fff;
    font-size: 1.25rem;
  }

  .ant-picker-suffix {
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 1560px) {
    height: 36px;
    font-size: 1rem;

    .ant-picker-input > input {
      font-size: 1rem;
    }
  }

  @media (max-width: 991px) {
    height: 31px;
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    background-color: #323232;

    .ant-picker-panel {
      border: 1px solid #323232;
    }

    .ant-picker-header {
      border-bottom: 1px solid rgb(0 0 0 / 40%);

      button {
        color: #fff;
      }
    }

    .ant-picker-content {
      .ant-picker-cell {
        color: rgb(255 255 255 / 25%);

        &:hover .ant-picker-cell-inner {
          background: #222222 !important;
          color: #fff;
        }
      }

      .ant-picker-cell {
        color: #ffffff;

        &.ant-picker-cell-disabled {
          color: rgba(255, 255, 255, 0.25);
        }
      }
    }

    .ant-picker-footer {
      border-top: 1px solid rgb(0 0 0 / 40%);
    }
  }
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background: transparent;
  border-color: #c00;
  color: #c00;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input-focused {
  box-shadow: none;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: transparent;
  border-color: #c00;
}

.ant-form-item-explain-error {
  color: #c00;
  text-align: left;
}

.uploadWrapper {
  display: inline-block;

  .uploadBtn {
    padding: 10px 30px;
    border: 1px solid rgb(255 255 255 / 50%);
    border-radius: 2px;
    cursor: pointer;
    margin-top: 10px;
  }

  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: transparent;
  }

  .ant-upload-list-item-info {
    .anticon-loading .anticon,
    .ant-upload-text-icon .anticon {
      color: rgb(255 255 255 / 45%);
    }

    .ant-upload-list-item-card-actions .anticon {
      color: rgb(255 255 255 / 45%);
    }
  }

  .ant-upload-list-text-container {
    .ant-tooltip {
      display: none;
    }
  }
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  // background: #a6a6a6;
  background: fade(@primary-color, 35%);
}

// .ant-picker-cell-in-view.ant-picker-cell-in-range {
//   .ant-picker-cell-inner {
//     color: #000;
//   }
// }

.ant-picker-clear {
  background-color: @PrimaryBlackColor;
  .anticon svg {
    path {
      fill: @primary-color;
    }
  }
}

.ant-divider {
  &.ant-divider-horizontal {
    margin: 10px auto;
    border-color: fade(@textWhite, 15%);
  }
}
