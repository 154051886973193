.travelFreeForm {
  .ServiceBox {
    border: @border;
    padding: 25px 25px 0;
    margin: 0 0 30px;
    width: 100%;
    .serviceType {
      .ant-form-item-label > label {
        font-family: @title_font;
        font-size: 1.5rem;
        letter-spacing: 0.04em;
        color: @textWhite;
        margin-bottom: 10px;
      }
      .ant-radio-group {
        display: flex;
        gap: 80px;
        .ant-radio-wrapper-in-form-item {
          color: #808080;
          font-size: 1.2rem;
          &.ant-radio-wrapper-checked {
            color: #fff;
          }
        }
      }
    }

    .acceptNoValue {
      margin: 0 0 2px;
    }

    .grandTotal {
      display: inline-block;
      margin: -30px 0 0;
      padding: 15px 20px;
      font-size: 26px;
      line-height: 1;
      background-color: @SecondaryBlackColor;
      color: @textWhite;
    }
    @media (max-width: 767px) {
      padding: 15px 15px 0;
      width: 90%;
      margin: 0 auto 15px;
      .serviceType {
        .ant-form-item-label > label {
          font-size: 2rem;
          letter-spacing: 0.065em;
        }
        .ant-radio-group {
          flex-wrap: wrap;
          gap: 15px;
        }
      }

      .grandTotal {
        display: block;
        margin: 0 0 20px;
        text-align: center;
        font-size: 25px;
      }
    }
  }
}

.responsive {
  margin-bottom: 40px;
  &.baggageTable {
    width: 100%;
    text-align: center;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: @primary_font;
    thead {
      tr {
        th {
          background-color: #cbcbcb;
          color: @SecondaryBlackColor;
          font-weight: 400;
          font-size: 1.3rem;
          line-height: 1.4;
          padding: 8px 10px;
          margin: 0;
          border: none;
        }
      }
    }
    tbody {
      tr {
        border-top: 6px solid @PrimaryBlackColor;
        background-color: @SecondaryBlackColor;
        td {
          padding: 10px 25px;
          border-right: 2px solid @PrimaryBlackColor;
          background-color: transparent;
          color: #fff;
          border: none;
          font-weight: 400;
          font-size: 28px;
          text-align: center;
          &.details {
            font-size: 16px;
            text-align: left;
          }

          .qty {
            background-color: transparent;
            color: #fff;
            border: none;
            font-family: @primary_font;
            font-weight: 400;
            font-size: 30px;
            text-align: center;
            width: 70px;
            padding: 0 5px;
            input {
              text-align: center;
            }
          }

          .incDec {
            background-color: transparent;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 44px;
            width: 44px;
            height: 44px;
            padding: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            cursor: pointer;
          }
          &:last-child {
            border: none;
          }
        }
        &.active {
          background-color: #000;
        }
        &:first-child {
          background-color: transparent;
          td {
            padding: 0;
          }
        }
      }
    }

    @media (max-width: 991px) {
      tbody tr td {
        padding: 6px 10px;
        font-size: 20px;
        &.details {
          font-size: 14px;
        }
        .incDec {
          width: 25px;
          height: 25px;
        }
        .qty,
        .bagPrice {
          font-size: 20px;
        }
        .qty {
          &.ant-input-number {
            line-height: 1;
            height: 25px;
            translate: 0 -50%;
            top: 9px;
          }
          input,
          .ant-input-number-input {
            height: unset;
            padding: 0 5px;
            text-align: center;
            line-height: 1;
          }
        }
      }
    }
    @media (max-width: 767px) {
      thead {
        display: none;
      }
      tbody,
      tr,
      td {
        display: block;
        position: relative;
      }
      tbody tr td,
      tbody tr td.details {
        text-align: right;
        padding: 10px 20px;
      }
      tbody tr td::before {
        content: attr(data-label);
        display: inline-block;
        text-align: left;
        position: absolute;
        top: 10px;
        left: 20px;
        z-index: 3;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
        opacity: 0.45;
      }
      tbody tr.active td::before {
        opacity: 0.8;
      }
    }
  }
}
